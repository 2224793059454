import Container from 'react-bootstrap/Container'
import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FormControl, InputGroup, Modal } from 'react-bootstrap'
import { MdEmail, MdLock, MdErrorOutline } from 'react-icons/md'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import ReactCodeInput from 'react-code-input'
import { AiOutlineClose } from 'react-icons/all'
import Spinner from 'react-bootstrap/Spinner'
// import swal from 'sweetalert2';
// import UserModels from "../../models/User";

import logoHPI from '../assets/logoNew.png'
import Palette from '../Palette'
import Member from '../models/Member'
import Swal from 'sweetalert2'
import Alert from '../components/Alert'

// import {
//     CButton,
//     CCol,
//     CFormInput,
//     CModal,
//     CModalBody,
//     CModalHeader,
//     CNav,
//     CNavItem,
//     CNavLink,
//     CRow
// } from '@coreui/react'

let requestCountDownTime = 0

function LoginPage(props) {
    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null)

    const [isEmailFocused, setEmailFocused] = useState(false)
    const [isPasswordFocused, setPasswordFocused] = useState(false)

    const [getEmail, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [timeToSendResetPasswordLink, setTimeToSendResetPasswordLink] = useState(0)
    const [isResetPasswordModalVisible, setResetPasswordModalVisible] = useState(false)
    const [resetPasswordCode, setResetPasswordCode] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isConfirmPasswordFocused, setConfirmPasswordFocused] = useState(false)
    const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [isButtonHovered, setButtonHovered] = useState(false)
    const [isLoginShown, setLoginShown] = useState(false)
    const [showForgotPassword, setForgotPassword] = useState(false)
    const [detailsTitle, setDetailsTitle] = useState(null)
    const [details, setDetails] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [selectedProvince, setSelectedProvince] = useState(null)
    const [province, setProvince] = useState('')
    const [provinces, setProvinces] = useState([])

    let memberModel = new Member()

    useEffect(() => {
        if (localStorage.getItem('user')) {
            props.history.replace('/')
        }
    }, [])

    const doLogin = async () => {
        setErrorMsg(null)
        setSuccessMsg(null)

        if (getEmail.length === 0) {
            setErrorMsg('Email tidak boleh kosong')
        } else if (password.length === 0) {
            setErrorMsg('Kata sandi tidak boleh kosong')
        } else {
            let model = new Member()

            setLoading(true)
            try {
                let result = await model.login({
                    email: getEmail,
                    password,
                })
                console.log('logins', result)

                if (result.membership_status === '30_DAY_TO_EXPIRY') {
                    await Swal.fire({
                        title: 'Peringatan',
                        text: 'Iuran Tahunan Saudara akan segera jatuh tempo. Harap menyelesaikan proses pembayaran melalui laman keanggotaan.',
                        icon: 'warning',
                        confirmButtonText: 'Oke',
                    })
                }

                /* if(result.membership_status === "RELEASE_GRACE_PERIOD_WARNING"){
                    await Swal.fire({
                        title: 'Peringatan',
                        html: `Iuran Tahunan Saudara telah jatuh tempo, harap mengurus keanggotaan anda sebelum tanggal 31 Juni 2024.
                        <br/><br/>
                        Iuran ini penting agar Saudara dapat tetap mengakses akun Sihapei Saudara dan menikmati berbagai manfaatnya. Harap menyelesaikan proses pembayaran melalui laman keanggotaan.`,
                        icon: 'warning',
                        confirmButtonText: 'Oke'
                    })
                } */

                if (result.membership_status === 'PAST_EXPIRY_DAY') {
                    await Swal.fire({
                        title: 'Peringatan',
                        text: 'Iuran Tahunan Saudara telah jatuh tempo. Iuran ini penting agar Saudara dapat tetap mengakses akun Sihapei Saudara dan menikmati berbagai manfaatnya. Harap menyelesaikan proses pembayaran melalui laman keanggotaan.',
                        icon: 'warning',
                        confirmButtonText: 'Oke',
                    })
                }

                // setRegisterAlert('Anda telah berhasil terdaftar! Harap periksa surel yang terdaftar untuk mengonfirmasi akunmu.')
                // setRegisterShown(false);
                // setLoginShown(true)
                localStorage.setItem('user', JSON.stringify(result))
                localStorage.setItem(
                    'expired_at',
                    new Date(new Date().getTime() + 60 * 60 * 27 * 1000),
                )

                setLoading(false)
                props.history.push('/member/akun')
            } catch (e) {
                console.log('eeeeeeeeee', e)
                // setErrorMsg(e.error_message)
                if (e.code === 'NONACTIVATED_ACCOUNT') {
                    await Swal.fire({
                        title: 'Terjadi Kesalahan',
                        text: 'Akun Sihapei Saudara telah dinonaktifkan. Harap selesaikan tunggakan Saudara dengan menghubungi Sekretariat HPI.',
                        icon: 'error',
                        confirmButtonText: 'Oke',
                    })
                } else {
                    await Swal.fire({
                        title: 'Terjadi Kesalahan',
                        text: e.error_message ? e.error_message : '',
                        icon: 'error',
                        confirmButtonText: 'Oke',
                    })
                }

                setLoading(false)
            }
        }
    }

    const submissionForgotPassword = async () => {
        setErrorMsg(null)
        setSuccessMsg(null)

        setLoading(true)

        const body = {
            email: getEmail,
        }

        try {
            console.log('TEST ID : ' + body.email)
            let result = await memberModel.forgotPassword(body)
            console.error('INI GAK KELUAR')
            setForgotPassword(false)
            setResetPasswordModalVisible(true)

            setTimeToSendResetPasswordLink(90)
            requestCountDownTime = 90

            setLoading(false)
            if (result.id || result.success) {
                Swal('', 'Data stored successfully!', 'success')
                setSuccessMsg('Password successfully changed!')
            } else {
                Swal('', 'Data failed to store', 'error')
                return
            }
        } catch (e) {
            if (e.code === 'EMAIL NOT FOUND_NOT_FOUND') {
                setErrorMsg('Surel tidak terdaftar!')
            } else {
                // setErrorMsg('Terjadi kesalahan.')
                console.log('error account nto rregistered')
            }
            console.log('forgotPassword err', e)

            setLoading(false)
        }

        // const submitConfirm = await Swal({
        //     title: "",
        //     text: "Apakah anda yakin dengan email ini",
        //     icon: "warning",
        //     buttons: ["Batal", "Ya, Simpan"],
        //     dangerMode: true,
        // });
        //
        // if (submitConfirm) {
        //     try {
        //         console.log("TEST ID : "+ body.email)
        //         let result = await memberModel.forgotPassword(body)
        //         console.error("INI GAK KELUAR")
        //         setForgotPassword(false)
        //         setResetPasswordModalVisible(true)
        //
        //         setTimeToSendResetPasswordLink(90)
        //         requestCountDownTime = 90
        //
        //         setLoading(false)
        //         if (result.id || result.success) {
        //             Swal('', "Data berhasil di simpan", 'success')
        //         } else {
        //             Swal('', "Data gagal disimpan", 'error')
        //             return
        //         }
        //
        //     } catch (e) {
        //         if (e.code === 'EMAIL NOT FOUND_NOT_FOUND') {
        //             setErrorMsg('Akun belum terdaftar!')
        //         } else {
        //             setErrorMsg('Terjadi kesalahan.')
        //         }
        //         console.log('forgotPassword err', e)
        //
        //         setLoading(false)
        //     }
        // }
    }

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (isResetPasswordModalVisible) submissionResetPassword()
            else doLogin()
        }
    }
    useEffect(() => {
        let countDownTimeSetInterval = setInterval(function () {
            if (requestCountDownTime > 0) {
                requestCountDownTime--
            }
            setTimeToSendResetPasswordLink(requestCountDownTime)
        }, 1000)
    }, [])
    const submissionResetPassword = async () => {
        setErrorMsg(null)
        setSuccessMsg(null)

        if (resetPasswordCode.length === 0) {
            setErrorMsg('Kode verifikasi tidak boleh kosong!')
        } else if (password.length === 0) {
            setErrorMsg('Kata Sandi tidak boleh kosong!')
        } else if (confirmPassword.length === 0) {
            setErrorMsg('Konfirmasi Kata Sandi tidak boleh kosong')
        } else if (password !== confirmPassword) {
            setErrorMsg('Kata Sandi dan Konfirmasi Kata Sandi tidak boleh kosong !')
        } else {
            let model = new Member()

            setLoading(true)

            try {
                // console.log('liat dong', {
                //     getEmail,
                //     new_password: password,
                //     token: resetPasswordCode
                // })

                let result = await model.resetPassword({
                    token: resetPasswordCode,
                    new_password: password,
                    email: getEmail,
                })

                setResetPasswordModalVisible(false)
                setLoginShown(true)
                setSuccessMsg('Kata Sandi berhasil diubah!')
                setLoading(false)
            } catch (e) {
                if (e.code === 'TOKEN  IS NOT_FOUND') {
                    setErrorMsg('Kode verifikasi tidak sesuai!')
                } else {
                    setErrorMsg('Kode tidak sesuai')
                }
                console.log('resetPassword err', e)

                setLoading(false)
            }
        }
    }

    const resetModal = () => {
        setDetailsTitle(null)
        setShowDetails(false)
        setForgotPassword(false)
    }

    return (
        <Container style={{ minHeight: 'calc(100vh - 252px)' }}>
            {/*<CModal*/}
            {/*    visible={showForgotPassword}*/}
            {/*    size={'lg'}*/}
            {/*    onClose={() => resetModal()}*/}
            {/*>*/}
            {/*    <CModalHeader style={borderBottom: '1px solid #fcf3f3',*/}
            {/*        fontSize: '1em',*/}
            {/*        color: '#ababab',*/}
            {/*        fontWeight: '600',}>*/}
            {/*        {detailsTitle}*/}
            {/*    </CModalHeader>*/}
            {/*    <CModalBody>*/}
            {/*        <CRow className='mb-3' style={marginBottom: '20px'}>*/}
            {/*            <CCol xs="6" md="6">*/}
            {/*                <label style={Styles.ModalDetailLabel} htmlFor="label-input">Member ID</label>*/}
            {/*                <div style={Styles.ModalDetailText}>*/}
            {/*                    {details && details.id}*/}
            {/*                </div>*/}

            {/*            </CCol>*/}
            {/*            <CCol xs="6" md="6">*/}
            {/*                <label style={Styles.ModalDetailLabel} htmlFor="label-input">Member Email</label>*/}
            {/*                /!*<div style={Styles.ModalDetailText}>*!/*/}
            {/*                /!*  {details && details.username}*!/*/}
            {/*                /!*</div>*!/*/}
            {/*                <CFormInput*/}
            {/*                    defaultValue={details && details.email}*/}
            {/*                    onChange={(e) => setEmail(e.target.value)}*/}
            {/*                    type="text" id="editEmail" />*/}
            {/*            </CCol>*/}
            {/*        </CRow>*/}
            {/*        <CButton color="primary" onClick={() => {*/}
            {/*            console.error(details.email)*/}
            {/*            setEmail(details.email)*/}
            {/*            console.error(email)*/}
            {/*            submissionForgotPassword(details.email)*/}
            {/*        }}>Save changes</CButton>*/}
            {/*    </CModalBody>*/}
            {/*</CModal>*/}

            <Modal
                show={showForgotPassword}
                onHide={() => {
                    setErrorMsg(null)
                    resetModal()
                }}
            >
                <Container>
                    <Row
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: -30,
                        }}
                    >
                        <AiOutlineClose
                            size={25}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setErrorMsg(null)
                                setForgotPassword(false)
                            }}
                        />
                    </Row>
                    <Row
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '2em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={'/logo.png'}
                            style={{
                                maxWidth: 100,
                                maxHeight: 100,
                                objectFit: 'contain',
                                borderRadius: 5,
                                cursor: 'pointer',
                                marginTop: 60,
                            }}
                        />
                    </Row>
                    <Row
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '800',
                            fontSize: '1.75em',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                            color: Palette.PRIMARY,
                            marginTop: 30,
                        }}
                    >
                        Lupa Kata Sandi
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <Col xs={1} />
                        <Col xs={10}>
                            {errorMsg ? (
                                <Row
                                    style={{
                                        backgroundColor: '#ffc9cf',
                                        color: '#e3192d',
                                        alignItems: 'center',
                                        border: '1px solid #d5bab9',
                                        paddingRight: 10,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        marginBottom: 20,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Col xs={1}>
                                        <MdErrorOutline size={27} color={'#a25b5d'} />
                                    </Col>
                                    <Col
                                        style={{
                                            color: '#a25b5d',
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            marginLeft: 5,
                                        }}
                                    >
                                        {errorMsg}
                                    </Col>
                                </Row>
                            ) : null}

                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    fontSize: '0.75em',
                                    color: '#8e8e8e',
                                    marginBottom: 7,
                                }}
                            >
                                SUREL
                            </div>
                            <InputGroup
                                style={
                                    isEmailFocused
                                        ? {
                                              boxShadow: '0 0 10px #9ecaed',
                                              border: '1px solid  #9ecaed',
                                              borderRadius: 5,
                                          }
                                        : {}
                                }
                            >
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#e9f3f4',
                                        }}
                                    >
                                        <MdEmail size={20} />
                                    </InputGroup.Text>

                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '75%',
                                                width: 2,
                                                backgroundColor: '#9ca4a6',
                                            }}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    onKeyPress={onKeyPress}
                                    placeholder="Surel"
                                    style={{
                                        borderWidth: 0,
                                        backgroundColor: '#e9f3f4',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        fontFamily: 'Signika',
                                        fontWeight: '600',
                                    }}
                                    onChange={(event) => setEmail(event.target.value)}
                                    onFocus={() => setEmailFocused(true)}
                                    onBlur={() => setEmailFocused(false)}
                                    value={getEmail}
                                />
                            </InputGroup>

                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontSize: '0.75em',
                                    color: '#8e8e8e',
                                    marginBottom: 7,
                                    marginTop: 4,
                                }}
                            >
                                Kode validasi untuk pemulihan sandi akan dikirim melalui surel.
                                Mohon tunggu beberapa menit.
                                {/*Kode validasi untuk memulihkan sandi akan dikirim melalui email dan mungkin membutuhkan*/}
                                {/*beberapa menit*/}
                            </div>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 25,
                            marginBottom: 15,
                        }}
                    >
                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                paddingTop: 10,
                                paddingBottom: 10,
                                width: 194,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                opacity: isLoading ? 0.3 : isButtonHovered ? 0.9 : 1,
                                fontSize: '.9em',
                            }}
                            onMouseEnter={() => setButtonHovered(true)}
                            onMouseLeave={() => setButtonHovered(false)}
                            onClick={submissionForgotPassword}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <Spinner
                                    animation="border"
                                    size={'sm'}
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            ) : (
                                'Kirim Kode Validasi'
                            )}
                        </Button>
                    </Row>
                </Container>
            </Modal>

            <Modal
                show={isResetPasswordModalVisible}
                onHide={() => {
                    setErrorMsg(null)
                    setResetPasswordModalVisible(false)
                }}
            >
                <Container>
                    <Row
                        style={{
                            marginTop: 15,
                            marginRight: 15,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: -30,
                        }}
                    >
                        <AiOutlineClose
                            size={25}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setErrorMsg(null)
                                setResetPasswordModalVisible(false)
                            }}
                        />
                    </Row>
                    <Row
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '2em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {/*<img src={'/logo.png'}*/}
                        {/*     style={{*/}
                        {/*       maxWidth: 100,*/}
                        {/*       maxHeight: 100,*/}
                        {/*       objectFit: 'contain',*/}
                        {/*       borderRadius: 5,*/}
                        {/*       cursor: 'pointer',*/}
                        {/*       marginTop: 60*/}
                        {/*     }}*/}
                        {/*/>*/}
                    </Row>
                    <Row
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '800',
                            fontSize: '1.75em',
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                            color: Palette.PRIMARY,
                            marginTop: 30,
                        }}
                    >
                        Lupa Sandi
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <Col xs={1} />
                        <Col xs={10}>
                            {errorMsg ? (
                                <Row
                                    style={{
                                        backgroundColor: '#ffc9cf',
                                        color: '#e3192d',
                                        alignItems: 'center',
                                        border: '1px solid #d5bab9',
                                        paddingRight: 10,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                        marginBottom: 20,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Col xs={1}>
                                        <MdErrorOutline size={27} color={'#a25b5d'} />
                                    </Col>
                                    <Col
                                        style={{
                                            color: '#a25b5d',
                                            fontFamily: 'Signika',
                                            fontWeight: '600',
                                            marginLeft: 5,
                                        }}
                                    >
                                        {errorMsg}
                                    </Col>
                                </Row>
                            ) : null}

                            <div
                                style={{
                                    fontFamily: 'Signika',
                                    fontSize: '0.75em',
                                    color: '#8e8e8e',
                                    marginBottom: 7,
                                    marginTop: 4,
                                }}
                            >
                                Masukkan kode verifikasi yang telah dikirimkan ke alamat surel
                                Saudara. ({getEmail}). <br />
                                <br />
                                Tidak menerima surelnya? Mohon periksa Kotak Spam atau Kotak Promosi
                                yang terdapat di surel Saudara. <br />
                                <br />
                                Tidak menerima kodenya? Kirim ulang kode verifikasi
                                {/*Mohon masukan kode verifikasi yang dikirimkan ke surel Anda ({getEmail}).<br/><br/>*/}
                                {/*Tidak menerima surel? Harap periksa spam dan kolom promosi pada penyedia surel*/}
                                {/*Anda.<br/><br/>*/}
                                {/*Masih tidak menerima kode?*/}
                                <a
                                    href={'#'}
                                    onClick={() => {
                                        submissionForgotPassword()
                                    }}
                                    style={{
                                        marginLeft: 5,
                                        pointerEvents:
                                            timeToSendResetPasswordLink > 0 ? 'none' : undefined,
                                        color: timeToSendResetPasswordLink > 0 ? 'grey' : undefined,
                                    }}
                                >
                                    Kirim ulang kode verifikasi
                                    {timeToSendResetPasswordLink > 0
                                        ? ` (${timeToSendResetPasswordLink})`
                                        : ``}
                                    .
                                </a>
                            </div>

                            <div style={{ textAlign: 'center', marginTop: 15 }}>
                                <ReactCodeInput
                                    type="text"
                                    fields={5}
                                    onChange={(value) => setResetPasswordCode(value)}
                                />
                            </div>

                            <div
                                style={{
                                    // fontFamily: 'Signika',
                                    fontWeight: '600',
                                    fontSize: '0.75em',
                                    color: '#8e8e8e',
                                    marginBottom: 7,
                                    marginTop: 20,
                                }}
                            >
                                Kata Sandi
                            </div>
                            <InputGroup
                                style={{
                                    backgroundColor: '#e9f3f4',
                                    boxShadow: isPasswordFocused ? '0 0 10px #9ecaed' : 'none',
                                    border: isPasswordFocused ? '1px solid  #9ecaed' : 'none',
                                    borderRadius: 5,
                                }}
                            >
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#e9f3f4',
                                        }}
                                    >
                                        <MdLock size={20} />
                                    </InputGroup.Text>

                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '75%',
                                                width: 2,
                                                backgroundColor: '#9ca4a6',
                                            }}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    onKeyPress={onKeyPress}
                                    placeholder="Kata Sandi"
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    style={{
                                        borderWidth: 0,
                                        backgroundColor: '#e9f3f4',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        // fontFamily: 'Signika',
                                        fontWeight: '600',
                                    }}
                                    onChange={(event) => setPassword(event.target.value)}
                                    onFocus={() => setPasswordFocused(true)}
                                    onBlur={() => setPasswordFocused(false)}
                                />

                                <InputGroup.Prepend
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        marginRight: 10,
                                        backgroundColor: '#e9f3f4',
                                    }}
                                    onClick={() => setPasswordVisible(!isPasswordVisible)}
                                >
                                    {isPasswordVisible ? (
                                        <IoMdEye size={20} />
                                    ) : (
                                        <IoMdEyeOff size={20} />
                                    )}
                                </InputGroup.Prepend>
                            </InputGroup>

                            <div
                                style={{
                                    // fontFamily: 'Signika',
                                    fontWeight: '600',
                                    fontSize: '0.75em',
                                    color: '#8e8e8e',
                                    marginBottom: 7,
                                    marginTop: 20,
                                }}
                            >
                                Konfirmasi Kata Sandi
                            </div>
                            <InputGroup
                                style={{
                                    backgroundColor: '#e9f3f4',
                                    boxShadow: isConfirmPasswordFocused
                                        ? '0 0 10px #9ecaed'
                                        : 'none',
                                    border: isConfirmPasswordFocused
                                        ? '1px solid  #9ecaed'
                                        : 'none',
                                    borderRadius: 5,
                                }}
                            >
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#e9f3f4',
                                        }}
                                    >
                                        <MdLock size={20} />
                                    </InputGroup.Text>

                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '75%',
                                                width: 2,
                                                backgroundColor: '#9ca4a6',
                                            }}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    onKeyPress={onKeyPress}
                                    placeholder="Konfirmasi Kata Sandi"
                                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                                    style={{
                                        borderWidth: 0,
                                        backgroundColor: '#e9f3f4',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        // fontFamily: 'Signika',
                                        fontWeight: '600',
                                    }}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    onFocus={() => setConfirmPasswordFocused(true)}
                                    onBlur={() => setConfirmPasswordFocused(false)}
                                />

                                <InputGroup.Prepend
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        marginRight: 10,
                                        backgroundColor: '#e9f3f4',
                                    }}
                                    onClick={() =>
                                        setConfirmPasswordVisible(!isConfirmPasswordVisible)
                                    }
                                >
                                    {isConfirmPasswordVisible ? (
                                        <IoMdEye size={20} />
                                    ) : (
                                        <IoMdEyeOff size={20} />
                                    )}
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 25,
                            marginBottom: 15,
                        }}
                    >
                        <Button
                            style={{
                                fontFamily: 'Signika',
                                fontWeight: '600',
                                paddingTop: 10,
                                paddingBottom: 10,
                                width: 194,
                                backgroundColor: Palette.PRIMARY,
                                borderColor: Palette.PRIMARY,
                                opacity: isLoading ? 0.3 : isButtonHovered ? 0.9 : 1,
                                fontSize: '.9em',
                            }}
                            onMouseEnter={() => setButtonHovered(true)}
                            onMouseLeave={() => setButtonHovered(false)}
                            onClick={() => submissionResetPassword()}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <Spinner
                                    animation="border"
                                    size={'sm'}
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            ) : (
                                'Ubah Sandi'
                            )}
                        </Button>
                    </Row>
                </Container>
            </Modal>
            {/*<Row*/}
            {/*    style={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Col lg={7} className="m-auto">*/}
            {/*        <Alert*/}
            {/*            backgroundColor={'#fff3cd'}*/}
            {/*            color={'#856404'}*/}
            {/*            type="warning"*/}
            {/*            className="mx-0"*/}
            {/*            errorMsg={`Menyusul ketentuan administrasi keanggotaan baru yang diatur dalam AD/ART HPI 2025-2027, */}
            {/*                proses pengajuan keanggotaan DITUTUP hingga 31 Maret 2025, dan dibuka kembali pada 01 April 2025.`}*/}
            {/*        ></Alert>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row
                style={{
                    fontFamily: 'Signika',
                    fontWeight: '600',
                    fontSize: '2em',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={logoHPI}
                    style={{
                        width: 200,
                    }}
                    alt="sihapei"
                />
            </Row>
            <Row
                style={{
                    fontFamily: 'Signika',
                    fontWeight: '800',
                    fontSize: '1.75em',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: Palette.PRIMARY,
                    marginTop: 30,
                }}
            >
                MASUK
            </Row>

            <Row style={{ marginTop: 20 }}>
                <Col lg={4} className="m-auto">
                    {errorMsg ? (
                        <Row
                            style={{
                                backgroundColor: '#ffc9cf',
                                color: '#e3192d',
                                alignItems: 'center',
                                border: '1px solid #d5bab9',
                                paddingRight: 10,
                                paddingTop: 7,
                                paddingBottom: 7,
                                marginBottom: 20,
                                borderRadius: 5,
                            }}
                        >
                            <Col xs={1}>
                                <MdErrorOutline size={27} color={'#a25b5d'} />
                            </Col>
                            <Col
                                style={{
                                    color: '#a25b5d',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    marginLeft: 5,
                                }}
                            >
                                {errorMsg}
                            </Col>
                        </Row>
                    ) : null}

                    {successMsg ? (
                        <Row
                            style={{
                                backgroundColor: '#81fd86',
                                color: '#109815',
                                alignItems: 'center',
                                border: '1px solid #d5bab9',
                                paddingRight: 10,
                                paddingTop: 7,
                                paddingBottom: 7,
                                marginBottom: 20,
                                borderRadius: 5,
                            }}
                        >
                            <Col xs={1}>
                                <MdErrorOutline size={27} color={'#109815'} />
                            </Col>
                            <Col
                                style={{
                                    color: '#109815',
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    marginLeft: 5,
                                }}
                            >
                                {successMsg}
                            </Col>
                        </Row>
                    ) : null}

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                        }}
                    >
                        SUREL
                    </div>
                    <InputGroup
                        style={
                            isEmailFocused
                                ? {
                                      boxShadow: '0 0 10px #9ecaed',
                                      border: '1px solid  #9ecaed',
                                      borderRadius: 5,
                                  }
                                : {}
                        }
                    >
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <MdEmail size={20} />
                            </InputGroup.Text>

                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <div
                                    style={{
                                        height: '75%',
                                        width: 2,
                                        backgroundColor: '#9ca4a6',
                                    }}
                                />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder="Surel"
                            style={{
                                borderWidth: 0,
                                backgroundColor: '#e9f3f4',
                                outline: 'none',
                                boxShadow: 'none',
                                fontFamily: 'Signika',
                                fontWeight: '600',
                            }}
                            onKeyPress={onKeyPress}
                            onChange={(event) => setEmail(event.target.value)}
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                            value={getEmail}
                        />
                    </InputGroup>

                    <div
                        style={{
                            fontFamily: 'Signika',
                            fontWeight: '600',
                            fontSize: '0.75em',
                            color: '#8e8e8e',
                            marginBottom: 7,
                            marginTop: 20,
                        }}
                    >
                        KATA SANDI
                    </div>
                    <InputGroup
                        style={{
                            backgroundColor: '#e9f3f4',
                            boxShadow: isPasswordFocused ? '0 0 10px #9ecaed' : 'none',
                            border: isPasswordFocused ? '1px solid  #9ecaed' : 'none',
                            borderRadius: 5,
                        }}
                    >
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#e9f3f4',
                                }}
                            >
                                <MdLock size={20} />
                            </InputGroup.Text>

                            <InputGroup.Text
                                style={{
                                    borderWidth: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <div
                                    style={{
                                        height: '75%',
                                        width: 2,
                                        backgroundColor: '#9ca4a6',
                                    }}
                                />
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            placeholder="Kata Sandi"
                            type={isPasswordVisible ? 'text' : 'password'}
                            style={{
                                borderWidth: 0,
                                backgroundColor: '#e9f3f4',
                                outline: 'none',
                                boxShadow: 'none',
                                fontFamily: 'Signika',
                                fontWeight: '600',
                            }}
                            onKeyPress={onKeyPress}
                            onChange={(event) => setPassword(event.target.value)}
                            onFocus={() => setPasswordFocused(true)}
                            onBlur={() => setPasswordFocused(false)}
                        />

                        <InputGroup.Prepend
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: 10,
                                marginRight: 10,
                                backgroundColor: '#e9f3f4',
                            }}
                            onClick={() => setPasswordVisible(!isPasswordVisible)}
                        >
                            {isPasswordVisible ? <IoMdEye size={20} /> : <IoMdEyeOff size={20} />}
                        </InputGroup.Prepend>
                    </InputGroup>

                    {/*<Link to="/forgot-password" className="mt-1">*/}
                    {/*    Forgot your password?*/}
                    {/*</Link>*/}

                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 25,
                            marginBottom: 20,
                        }}
                    >
                        <Col>
                            <Button
                                style={{
                                    fontFamily: 'Signika',
                                    fontWeight: '600',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    width: '100%',
                                    backgroundColor: Palette.PRIMARY,
                                    borderColor: Palette.PRIMARY,
                                }}
                                onClick={doLogin}
                                disabled={isLoading}
                                className="text-uppercase"
                            >
                                masuk
                            </Button>
                            {/* TEMPORARY REMOVED */}
                            {/* <div className="text-center mt-2">
                                Belum punya akun? <Link to="/register">Klik di sini</Link>
                            </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: 'center', marginBottom: 30 }}>
                            <a
                                href={`#`}
                                onClick={() => {
                                    setLoginShown(false)
                                    setForgotPassword(true)
                                    setErrorMsg(null)
                                }}
                                style={{ fontWeight: '600' }}
                            >
                                Lupa kata sandi?
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginPage

import { Button, Table } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import Logo from '../../assets/logo.png'
import Card from 'react-bootstrap/Card'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import IconButton from '@material-ui/core/IconButton'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { AiOutlineClose } from 'react-icons/all'
import Container from 'react-bootstrap/Container'
import Palette from '../../Palette'
import Member from '../../models/Member'

/*
{data?.transaction?.type}
1
{formatMoney(data?.transaction?.amount)}
{formatMoney(data?.transaction?.amount)}
 */

const getInvoiceDetail = (type, data) =>{

    console.log("GETID",data)

    if(type === "MEMBERSHIP"){
        return [
            {type : `Iuran Tahunan ${moment(data.valid_from).format("DD MMM YYYY")} - ${moment(data.valid_until).format("DD MMM YYYY")}`, unit : 1, price : data?.transaction?.amount, total : data?.transaction?.amount }
        ]
    }

    if(type === "FIRST_MEMBERSHIP"){
        let obj
        if(data.print_card) {
            obj = [
                {type : `Iuran Tahunan ${moment(data.valid_from).format("DD MMM YYYY")} - ${moment(data.valid_until).format("DD MMM YYYY")}`, unit : 1, price : data?.transaction?.amount - 250000 - 100000, total : data?.transaction?.amount - 250000 - 100000 },
                {type : "Biaya Registrasi", unit : 1, price : 250000, total : 250000 },
                {type : "Cetak Kartu Fisik", unit : 1, price : 100000, total : 100000 }
            ]
        } else {
            obj = [
                {type : `Iuran Tahunan ${moment(data.valid_from).format("DD MMM YYYY")} - ${moment(data.valid_until).format("DD MMM YYYY")}`, unit : 1, price : data?.transaction?.amount - 250000, total : data?.transaction?.amount - 250000 },
                {type : "Biaya Registrasi", unit : 1, price : 250000, total : 250000 }
            ]
        }
        return obj
    }

    if(type === "UPGRADE_MEMBERSHIP"){
        return [//validfrom valid until
            {type : `Iuran Anggota Profesional ${moment(data.valid_from).format("DD MMM YYYY")} - ${moment(data.valid_until).format("DD MMM YYYY")}`, unit : 1, price : data?.transaction?.amount - 50000, total : data?.transaction?.amount - 50000 },
            {type : "Kartu Anggota", unit : 1, price : 50000, total : 50000 }
        ]
    }

    return [
        {type : data?.transaction?.type, unit : 1, price : data?.transaction?.amount, total : data?.transaction?.amount }
    ]
}

export default function InvoiceDefaultModal(props) {

    const { show, close, data, profile } = props
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        close()
    }
    let formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'IDR',
    })

    const formatMoney = (amount) => {
        let reverse = amount?.toString().split('').reverse().join(''),
            th = reverse?.match(/\d{1,3}/g);
        th = th?.join('.').split('').reverse().join('');
        return th
    }

    const downloadInvoice = () => {
        html2canvas(document.getElementById('invoiceContent'), {
            scale: 1,
             allowTaint: true,
            useCORS: true,
            logging: true,
            quality: 4,
            dpi: 320
        }).then(function (canvas) {
            var img = canvas.toDataURL('image/png')
            var doc = new jsPDF('l', 'mm', [700, 900])
            doc.addImage(img, 'JPEG', 10, 10)
            doc.save("Invoice " + profile?.member?.full_name + ".pdf")
        })
    }

    const terbilang = (nilai) => {

        const huruf = ["", "Satu", "Dua", "Tiga", "Empat", "Lima", "Enam", "Tujuh", "Delapan", "Sembilan", "Sepuluh", "Sebelas"];
        let temp = "";
        if (nilai < 12) {
            temp = " " + huruf[nilai];
        } else if (nilai < 20) {
            temp = terbilang(nilai - 10) + " Belas";
        } else if (nilai < 100) {
            temp = terbilang(Math.floor(nilai / 10)) + " Puluh" + terbilang(nilai % 10);
        } else if (nilai < 200) {
            temp = " Seratus" + terbilang(nilai - 100);
        } else if (nilai < 1000) {
            temp = terbilang(Math.floor(nilai / 100)) + " Ratus" + terbilang(nilai % 100);
        } else if (nilai < 2000) {
            temp = " Seribu" + terbilang(nilai - 1000);
        } else if (nilai < 1000000) {
            temp = terbilang(Math.floor(nilai / 1000)) + " Ribu" + terbilang(nilai % 1000);
        } else if (nilai < 1000000000) {
            temp = terbilang(Math.floor(nilai / 1000000)) + " Juta" + terbilang(nilai % 1000000);
        } else if (nilai < 1000000000000) {
            temp = terbilang(Math.floor(nilai / 1000000000)) + " Milyar" + terbilang(Math.fmod(nilai, 1000000000));
        } else if (nilai < 1000000000000000) {
            temp = terbilang(Math.floor(nilai / 1000000000000)) + " Trilyun" + terbilang(Math.fmod(nilai, 1000000000000));
        }
        return temp;

    }

    console.log("inv data", props.data)

    return (
        <Modal show={show} onHide={handleClose} size={'xl'}>
            <IconButton
                onClick={handleClose}
                onMouseDown={(e) => e.preventDefault()}
                className="position-absolute"
                style={{ right: 0 }}
            >
                <AiOutlineClose color={Palette.PRIMARY} />
            </IconButton>
            <div id="invoiceContent">
                <Modal.Header>
                    <Row style={{ width: '100%' }}>
                        <Col className="mt-3" md={6}>
                            <div className="mt-3">
                                <p
                                    style={{ fontWeight: 'bold', fontSize: '1.5em' }}
                                    className="text-uppercase"
                                >
                                    Himpunan Penerjemah Indonesia
                                </p>
                                <p style={{ fontSize: '0.7em', color: '#333333', width: '80%' }}>
                                    Jalan Ciputat Raya No 6 RT 002 RW 02
                                    Kel. Pondok Pinang, Kec. Kebayoran Lama,
                                    Jakarta Selatan 12310
                                    <div className="mt-1">Phone: (021) 7514548, 087809000041</div>
                                    <div className="mt-1">Email: bendahara@hpi.or.id</div>
                                </p>
                            </div>
                        </Col>
                        <Col style={{ flex: 1, textAlign: 'right' }}>
                            <img
                                style={{ width: '100px' }}
                                src={Logo}
                                alt="himpunan penerjemah indonesia"
                            ></img>
                            <div className="mt-2">
                                <div style={{ display: 'flex' }}>
                                    <b style={{ width: '50%' }}>No.Tagihan: </b>{' '}
                                    <span className="text-right pl-5">
                                        {data?.transaction?.order_id}
                                    </span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <b style={{ width: '50%' }}>Tanggal Penerimaan: </b>{' '}
                                    <span className="text-right pl-5">
                                        {data?.transaction?.paid_at
                                            ? new moment(data?.transaction?.paid_at).format(
                                                'DD MMM YYYY',
                                            )
                                            : '-'}
                                    </span>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Card>
                        <Card.Body>


                            <Row>
                                <Col md={3} className="font-weight-bold">
                                    Kepada
                                </Col>
                                <Col md={8}><div style={{ display: 'flex' }}><span style={{ marginRight: '2px' }}>:</span><span style={{ border: '1px solid #000', width: '100%', padding: '2px' }}>{profile?.member?.full_name}</span></div></Col>
                            </Row>
                            <Row>
                                <Col md={3} className="font-weight-bold">
                                    Status Keanggotaan
                                </Col>
                                <Col>: <strong >Anggota <span style={{textTransform : "lowercase"}}>{profile?.member?.role}</span></strong></Col>
                            </Row>
                            <Row>
                                <Col md={3} className="font-weight-bold">
                                    No.Anggota
                                </Col>
                                <Col>: <strong>{profile?.member?.hpi_id}</strong></Col>
                            </Row>   <Row>
                                <Col md={3} className="font-weight-bold">
                                    Surel
                                </Col>
                                <Col>: {profile?.member?.email}</Col>
                            </Row>
                            {/*
                            <Row>
                                <Col md={3} className="font-weight-bold">
                                    <p>Banyaknya Uang </p>
                                </Col>
                                <Col>: {formatter.format(data?.transaction?.amount)}</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={3} className="font-weight-bold">
                                    <p>Untuk Pembayaran</p>
                                </Col>
                                <Col>: {data?.transaction?.type}</Col>
                            </Row>

                            <Row className="pt-2">
                                <Col md={3} className="font-weight-bold">
                                    <p>Jumlah </p>
                                </Col>
                                <Col>: {formatter.format(data?.transaction?.amount)}</Col>
                            </Row> */}
                            <Row className='mt-2'>
                                <Col md={12}>
                                    <table className='table table-borderless table-bordered-invoice'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th style={{ width: '60%' }}>URAIAN</th>
                                                <th>Unit</th>
                                                <th>Harga Per Unit (Rp.)</th>
                                                <th>Jumlah (Rp.)</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                        {
                                            getInvoiceDetail(data?.transaction?.type, data).map((obj, idx)=>{
                                                return <tr>
                                                    <td>
                                                        {idx + 1}
                                                    </td>
                                                    <td>
                                                        {obj.type}
                                                    </td>
                                                    <td>
                                                        1
                                                    </td>
                                                    <td>
                                                        {formatMoney(obj.price)}

                                                    </td>
                                                    <td>
                                                        {formatMoney(obj.total)}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td style={{
                                                    borderLeft: '1px solid #fff',
                                                    borderBottom: '1px solid #fff',
                                                }} colSpan={3}></td>
                                                <td style={{ border: '1px solid #000', fontWeight: 'bolder' }}>Total</td>
                                                <td style={{ border: '1px solid #000', fontWeight: 'bolder' }}>{formatMoney(data?.transaction?.amount)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </Col>

                            </Row>
                            <Row className="pt-2">

                                <Col md={2}>
                                    <strong>Terbilang</strong>
                                </Col>
                                <Col md={10}>
                                    : {terbilang(data?.transaction?.amount) + ' Rupiah'}
                                </Col>
                                <Col style={{ fontSize: '0.9em' }}>
                                    <div className="px-1" style={{ border: 'solid black' }}>
                                        <Row>
                                            <Col md={3}>Nama Bank</Col>
                                            <Col md={6}>: Mandiri</Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>Cabang Bank</Col>
                                            <Col md={6}>: Jakarta Sabang</Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>Nomor Rekening Bank</Col>
                                            <Col md={6}>: 103-00-0703952-8</Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col className="text-center" md={4}>
                                    <div>Jakarta, {moment(data?.transaction?.paid_at).format('DD MMM YYYY')}</div>
                                    <img
                                        style={{ width: '100px' }}
                                        src={Logo}
                                        alt="himpunan penerjemah indonesia"
                                    ></img>
                                    <div>
                                        <u>Farah Riziani Rachmat</u>
                                        <br />
                                        Bendahara HPI
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col style={{ fontSize: '0.65em' }}>
                                    Catatan:
                                    <ol>
                                        <li>HPI bukan PKP jadi tidak memotong pajak.</li>
                                        <li>
                                            Mohon cantumkan nama dan nomor anggota HPI (Internet Banking / ATM Nontunai) atau
                                            sertakan empat digit terakhir nomor anggota HPI (ATM Tunai).
                                        </li>
                                        <li>
                                            Mohon dibayarkan penuh sesuai dengan Tagihan ini dan kirimkan bukti pembayaran ke bendahara@hpi.or.id dengan
                                            tembusan ke sekretariat@hpi.or.id.
                                        </li>
                                    </ol>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </div>
            <Modal.Footer>
                <Button onClick={downloadInvoice}> Download Invoice</Button>
            </Modal.Footer>
        </Modal>
    )
}

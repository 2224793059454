// models/Language.js
import ApiRequest from '../util/ApiRequest'

export default class Language {
    static async getAllLanguages() {
        try {
            const response = await ApiRequest.set('/v1/languages', 'GET');
            return response; // Langsung return array dari API
        } catch (error) {
            console.error('Error fetching languages:', error);
            throw error;
        }
    }
}
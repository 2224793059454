import {Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import Alert from '../../components/Alert'
import BiographyForm from './BiographyForm'
import MembershipForm from './MembershipForm'
import PersonalDataForm from './PersonalDataForm'
import {RegistrationFormContext, useRegistrationForm} from './RegistrationFormContext'
import moment from 'moment'
import Member from '../../models/Member'

const steps = ['Data Diri', 'Jenis Keanggotaan', 'Riwayat Hidup']

const REGISTRATION_FEES = {
    registration: 250000,
    subscription_if_penuh_or_mitra: 350000,
    subscription_if_muda: 150000,
    card: 100000,
}

export default function RegisterPageV3() {

    const form = useRegistrationForm()

    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0)

    const [isDialogOpen, setDialogOpen] = useState(false)

    // Personal Data Form
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [gender, setGender] = useState('')
    const [birthPlace, setBirthPlace] = useState('')
    const [birthDate, setBirthDate] = useState(new Date())
    const [citizenship, setCitizenship] = useState('INDONESIAN')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState({})
    const [idNumber, setIdNumber] = useState('')
    const [idOrigin, setIdOrigin] = useState('')
    const [address, setAddress] = useState('')
    const [currentAddress, setCurrentAddress] = useState('')
    const [mobilePhone, setMobilePhone] = useState('')
    const [homePhone, setHomePhone] = useState('')
    const [profilePic, setProfilePic] = useState('')

    //form kedua
    const [membershipType, setMembershipType] = useState('PENUH')
    const [profession, setProfession] = useState('')
    const [translatorReqType, setTranslatorReqType] = useState('1')
    const [translatorReqFile, setTranslatorReqFile] = useState([])
    const [interpreterReqFile, setInterpreterReqFile] = useState([])
    const [agencyNamePlace, setAgencyNamePlace] = useState('')
    const [availability, setAvailability] = useState('')
    const [languageFrom, setLanguageFrom] = useState(null)
    const [languageTo, setLanguageTo] = useState(null)
    const [selectedLanguagePair, setSelectedLanguagePair] = useState([])
    const [languageSelection, setLanguageSelection] = useState([])
    const [declaration, setDeclaration] = useState(false)
    const [profileVisibilityAgreement, setProfileVisibilityAgreement] = useState('')
    const [regulationCompliance, setRegulationCompliance] = useState(false)
    const [informationDeclaration, setInformationDeclaration] = useState(false)
    const [termsAgreement, setTermsAgreement] = useState(false)
    const [printCardAgreement, setPrintCardAgreement] = useState(false)
    const [mitraReason, setMitraReason] = useState('')
    const [mitraKtpSuratKerjaFile, setMitraKtpSuratKerjaFile] = useState([])
    const [mitraKartuAnggotaFile, setMitraKartuAnggotaFile] = useState([])
    const [selectedSpecializations, setSelectedSpecializations] = useState([])

    // Biography Form
    const [isBnspCertified, setBnspCertified] = useState(true)
    const [bnspNumber, setBnspNumber] = useState('')
    const [joinReason, setJoinReason] = useState('')
    const [shortProfile, setShortProfile] = useState('')
    const [agreementUnderstandingDeclaration, setAgreementUnderstandingDeclaration] =
        useState(false)
    const [dataValidDeclaration, setDataValidDeclaration] = useState(false)
    const [complianceAgreement, setComplianceAgreement] = useState(false)
    const [complianceAgreement2, setComplianceAgreement2] = useState(false)

    useEffect(()=>{
        window.scroll(0,0)
    },[activeStep])

    const isPersonalDataFormFilled = () => {
        return (
            fullName &&
            email &&
            password &&
            passwordConfirm &&
            gender &&
            birthPlace &&
            birthDate &&
            citizenship &&
            province &&
            city &&
            idNumber &&
            idOrigin &&
            address &&
            mobilePhone &&
            profilePic
        )
    
    }
    const isMembershipFormFilled = () => {
        return (
            membershipType &&
            (membershipType === 'MITRA' ? (citizenship === "INDONESIAN" ? Boolean(mitraKtpSuratKerjaFile) : Boolean(mitraKartuAnggotaFile)) : true) &&
            (membershipType === 'PENUH' ? (Boolean(profession) && informationDeclaration && regulationCompliance && termsAgreement) : true) &&
            (profession === 'Translator' || profession === 'Both' ? (translatorReqFile.length > 0 && Boolean(translatorReqType)) : true) &&
            (profession === 'Interpreter' || profession === 'Both' ? interpreterReqFile.length > 0 : true) &&
            selectedLanguagePair.length > 0
        )
    }

    const isBiographyFormFilled = () => {
        return (
            Boolean(joinReason) &&
            shortProfile &&
            agreementUnderstandingDeclaration &&
            dataValidDeclaration &&
            complianceAgreement &&
            complianceAgreement2 &&
            (isBnspCertified ? Boolean(bnspNumber) : true)
        )
    }

    const isValidToContinue = () => {
        if (activeStep === 0) return isPersonalDataFormFilled()
        else if (activeStep === 1) return isMembershipFormFilled()
        else return isBiographyFormFilled()
    }

     const attemptRegister = async () => {
         setErrorMsg(null)

         let model = new Member()

         if (fullName.length === 0) {
             Swal.fire('', 'Harap isi Nama Lengkap', 'error')
             return
         }

         if (email.length === 0) {
             Swal.fire('', 'Harap isi Surel', 'error')
             return
         }

         if (mobilePhone.length === 0) {
             Swal.fire('', 'Harap Isi Nomor Telepon Seluler', 'error')
             return
         }

         if (password.length === 0) {
             Swal.fire('', 'Harap isi Kata Sandi', 'error')
             return
         }

         if (passwordConfirm.length === 0) {
             Swal.fire('', 'Harap isi Konfirmasi Kata Sandi', 'error')
             return
         }

         if (password !== passwordConfirm) {
             Swal.fire('', 'Kata Sandi dan Konformasi Kata Sandi Tidak Sesuai', 'error')
             return
         }

         if (gender.length === 0) {
             Swal.fire('', 'Harap pilih Jenis Kelamin', 'error')
             return
         }

         if (birthPlace.length === 0) {
             Swal.fire('', 'Harap isi Tempat Lahir', 'error')
             return
         }

         if (birthDate.length === 0) {
             Swal.fire('', 'Harap pilih Tanggal Lahir', 'error')
             return
         }

         if (citizenship.length === 0) {
             Swal.fire('', 'Harap Pilih Kewarganegaraan', 'error')
             return
         }

         if (idNumber.length === 0) {
             Swal.fire('', 'Harap isi Nomor KTP/PASPOR', 'error')
             return
         }

         if (idOrigin.length === 0) {
             Swal.fire('', 'Harap isi Tempat KTP diterbitkan', 'error')
             return
         }

         if (province.length === 0) {
             Swal.fire('', 'Harap pilih Provinsi', 'error')
             return
         }

         if (city.length === 0) {
             Swal.fire('', 'Harap pilih Kota/Kabupaten', 'error')
             return
         }

         if (address.length === 0) {
             Swal.fire('', 'Harap isi Alamat Lengkap', 'error')
             return
         }

         if (membershipType.length === 0) {
             Swal.fire('', 'Harap pilih Jenis Keanggotaan', 'error')
             return
         }

         if (membershipType === 'PENUH' && profession.length === 0) {
             Swal.fire('', 'Harap pilih Profesi', 'error')
             return
         }

         if (availability.length === 0) {
             Swal.fire('', 'Harap pilih Ketersediaan', 'error')
             return
         }

         if (selectedLanguagePair.length === 0) {
             Swal.fire('', 'Harap tentukan Pasangan Bahasa', 'error')
             return
         }

         if (!termsAgreement && membershipType === 'PENUH') {
             Swal.fire('', 'Harap menyetujui pernyataan keterikatan', 'error')
             return
         }

         if (!regulationCompliance && membershipType === 'PENUH') {
             Swal.fire('', 'Harap menyetujui pernyataan kepatuhan', 'error')
             return
         }

         if (!informationDeclaration && membershipType === 'PENUH') {
             Swal.fire('', 'Harap menyetujui Pernyataan Pemahan dan Kebenaran Informasi', 'error')
             return
         }

         if (shortProfile.length === 0) {
             Swal.fire('', 'Harap mengisi Riwayat Hidup Singkat', 'error')
             return
         }
         if (membershipType === 'PENUH' && profileVisibilityAgreement.length === 0) {
             Swal.fire('', 'Harap memilih Kebersediaan untuk menampilkan profil', 'error')
             return
         }
         if (joinReason.length === 0) {
             Swal.fire('', 'Harap mengisi Alasan Begabung', 'error')
             return
         }

         /* if (!tnCAgreement) {
             Swal.fire('', 'Harap menyetujui Syarat & Ketentuan yang berlaku di HPI', 'error')
             return
         } */

         if (moment().diff(moment(birthDate), 'years') <= 17) {
             Swal.fire('', 'Batas usia minimal untuk bergabung adalah 18 tahun', 'error')
             return
         }

         setLoading(true)

         try {
             let payload = {
                 honorific: '',
                 email,
                 password,
                 availability,
                 full_name: fullName,
                 gender,
                 address: currentAddress,
                 birth_place: birthPlace,
                 profession,
                 ssn: idNumber,
                 ss_created_location: idOrigin,
                 ss_address: address,
                 landline_number: homePhone,
                 phone_number: mobilePhone,
                 role: membershipType,
                 specialization: selectedSpecializations,
                 languages: selectedLanguagePair.map((obj) => {
                     return {
                         language_id_from: obj.language_from.value,
                         language_id_to: obj.language_to.value,
                     }
                 }),
                 company_name_location: agencyNamePlace,
                 type: availability,
                 is_public: membershipType === 'MUDA' ? false : profileVisibilityAgreement,
                 birth_date: moment(birthDate).format('YYYY-MM-DD'),
                 profile_picture_url: profilePic,
                 resume: shortProfile,
                 join_reason: joinReason,
                 citizenship: citizenship,
                 address_region: province,
                 address_locality: city,
                 print_card: printCardAgreement,
                 sertifikat_bnsp: bnspNumber

             }
             let fileRegistInterpreter = interpreterReqFile?.map((v) => {
                 return v.location
             });
             let fileRegistTranslator = translatorReqFile?.map((v) => {
                 return v.location
             });;

            
             if(membershipType === 'MITRA') {
                let fileRegistMitra
                if(citizenship === "INDONESIAN" && mitraKtpSuratKerjaFile != null) {
                    fileRegistMitra = mitraKtpSuratKerjaFile?.map((v) => {
                        return v.location
                    });
                    
                 } else if(citizenship === "Foreign" && mitraKartuAnggotaFile != null) {
                    fileRegistMitra = mitraKartuAnggotaFile?.map((v) => {
                        return v.location
                    });
                 }
                 payload.file_registration_mitra = fileRegistMitra
             }
             
             if (membershipType === 'PENUH') {
                 switch (profession) {
                     case "Translator":
                         if (translatorReqFile?.length === 0) {
                             Swal.fire('', 'Harap unggah pemenuhan Syarat Penerjemah','error')
                             setLoading(false)
                             return
                         }
                         payload.file_registration_requirements_type = translatorReqType
                         payload.file_registration_requirements = fileRegistTranslator
                         break;
                     case "Interpreter":
                         if (interpreterReqFile?.length === 0) {

                             Swal.fire('', 'Harap unggah pemenuhan syarat Juru Bahasa', 'error')
                             setLoading(false)
                             return
                         }
                         payload.file_reference_letter_interpreter = fileRegistInterpreter
                         break
                     default:
                         if (translatorReqFile?.length === 0) {
                             Swal.fire('', 'Harap unggah pemenuhan Syarat Penerjemah', 'error')
                             setLoading(false)
                             return
                         }

                         if (interpreterReqFile?.length === 0) {

                             Swal.fire('', 'Harap unggah pemenuhan syarat Juru Bahasa', 'error')
                             setLoading(false)
                             return
                         }

                         payload.file_registration_requirements_type = translatorReqType
                         payload.file_registration_requirements = [...fileRegistTranslator]
                         payload.file_reference_letter_interpreter = [...fileRegistInterpreter]
                 }
             }

            console.log('sending', payload)

            let result = await model.register(payload)
            Swal.fire({
                title: 'Informasi pendaftaran Saudara telah tersimpan',
                text: 'Anda akan diarahkan ke laman pembayaran untuk menyelesaikan proses pendaftaran.',
                icon: 'info',
                confirmButtonText: 'Oke',
            }).then(() => {
                window.location.replace(result.payment_link)
            })

             setLoading(false)
         } catch (e) {
             await Swal.fire({
                 title: 'Terjadi Kesalahan',
                 text: e.error_message,
                 icon: 'error',
                 confirmButtonText: 'OKE',
             })
             setLoading(false)
         }

     } 

    const value = {
        fullName,
        setFullName,
        email,
        setEmail,
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,
        gender,
        setGender,
        birthPlace,
        setBirthPlace,
        birthDate,
        setBirthDate,
        citizenship,
        setCitizenship,
        province,
        setProvince,
        city,
        setCity,
        idNumber,
        setIdNumber,
        idOrigin,
        setIdOrigin,
        address,
        setAddress,
        currentAddress,
        setCurrentAddress,
        mobilePhone,
        setMobilePhone,
        homePhone,
        setHomePhone,
        profilePic,
        setProfilePic,
        membershipType,
        setMembershipType,
        profession,
        setProfession,
        translatorReqType,
        setTranslatorReqType,
        translatorReqFile,
        setTranslatorReqFile,
        interpreterReqFile,
        setInterpreterReqFile,
        agencyNamePlace,
        setAgencyNamePlace,
        availability,
        setAvailability,
        languageFrom,
        setLanguageFrom,
        languageTo,
        setLanguageTo,
        selectedLanguagePair,
        setSelectedLanguagePair,
        languageSelection,
        setLanguageSelection,
        declaration,
        setDeclaration,
        profileVisibilityAgreement,
        setProfileVisibilityAgreement,
        regulationCompliance,
        setRegulationCompliance,
        informationDeclaration,
        setInformationDeclaration,
        termsAgreement,
        setTermsAgreement,
        printCardAgreement,
        setPrintCardAgreement,
        mitraReason,
        setMitraReason,
        mitraKtpSuratKerjaFile,
        setMitraKtpSuratKerjaFile,
        mitraKartuAnggotaFile,
        setMitraKartuAnggotaFile,
        isBnspCertified,
        setBnspCertified,
        bnspNumber,
        setBnspNumber,
        joinReason,
        setJoinReason,
        shortProfile,
        setShortProfile,
        agreementUnderstandingDeclaration,
        setAgreementUnderstandingDeclaration,
        dataValidDeclaration,
        setDataValidDeclaration,
        complianceAgreement,
        setComplianceAgreement,
        complianceAgreement2,
        setComplianceAgreement2,
        isValidToContinue,
        attemptRegister
    }

    return (
        <RegistrationFormContext.Provider value={value}>
            <Container>
                {/* Page title */}
                <Row className="page-title">Daftar</Row>

                <Row style={{ marginTop: 20}}>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ width: '100%' }}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </Row>

                {/* Form */}
                <Row style={{ marginTop: 20 }}>
                    <Col xl={12} lg={12} md={12} xs={12} className={'m-auto'}>
                        {errorMsg && (
                            <Alert type="error" className="mx-0" errorMsg={errorMsg}></Alert>
                        )}
                        {activeStep === 0 && <PersonalDataForm />}
                        {activeStep === 1 && <MembershipForm />}
                        {activeStep === 2 && <BiographyForm />}
                    </Col>
                </Row>

                <Row
                    style={{
                        marginTop: 20,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="d-flex justify-content-start">
                        {activeStep !== 0 && (
                            <Button onClick={() => {
                                setAgreementUnderstandingDeclaration(false)
                                setDataValidDeclaration(false)
                                setComplianceAgreement(false)
                                setComplianceAgreement2(false)
                                setActiveStep(activeStep - 1)
                            }}>Back</Button>
                        )}
                    </div>
                    <div className="d-flex justify-content-end">
                        {activeStep !== 2 /* && isValidToContinue() */ && (
                            <Button disabled={!isValidToContinue()} onClick={() => setActiveStep(activeStep + 1)}>Next</Button>
                        )}
                        {activeStep === 2 /* && isValidToContinue() */ && (
                          <Button
                            disabled={!isValidToContinue()}
                            onClick={() => {setDialogOpen(true)}}>Daftar</Button>
                        )}
                    </div>
                </Row>

                <PaymentDialog
                  open={isDialogOpen}
                  handlePayment={() => {
                      setDialogOpen(false)
                      attemptRegister()
                  }}
                  closeDialog={() => setDialogOpen(false)}
                />

            </Container>
        </RegistrationFormContext.Provider>
    )
}

function PaymentDialog({ open, handlePayment, closeDialog }) {
    const [fees, setFees] = useState({
        registration: REGISTRATION_FEES.registration,
        subscription: 0,
        card: 0,
        total: 0,
    })
    const form = useRegistrationForm()

    useEffect(() => {
        const feesData = {...fees}

        if (form.membershipType === 'PENUH' || form.membershipType === 'MITRA') {
            feesData.subscription = REGISTRATION_FEES.subscription_if_penuh_or_mitra
        } else {
            feesData.subscription = REGISTRATION_FEES.subscription_if_muda
        }

        console.log("CIP", form)

        if (form.printCardAgreement) {
            feesData.card = REGISTRATION_FEES.card
        }else{
            feesData.card = 0
        }

        setFees({
            ...fees,
            ...feesData,
            total: fees.registration + feesData?.subscription + feesData?.card,
        })
    }, [form.membershipType, form.printCardAgreement])

    return (
      <Dialog open={open} fullWidth={true} maxWidth="sm">
          <DialogTitle>Pembayaran</DialogTitle>
          <DialogContent>
              <div>
                  <PaymentDialogItem
                    label={'Detail Pembayaran'}
                    value={moment().format('DD MMMM YYYY')}
                  />
                  <div style={{ marginTop: 24 }}>
                      <Alert
                        backgroundColor={'#E3E8F8'}
                        color={'#284A9A'}
                        type="info"
                        className="mx-0"
                        errorMsg={`Jika pengajuan anda ditolak, biaya registrasi akan dikembalikan setelah dipotong Rp50.000 untuk biaya administrasi`}
                        textStyle={{ fontSize: 12, fontWeight: 500 }}
                      />
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ marginTop: 24, gap: 16, marginBottom: 48 }}
                  >
                      <PaymentDialogItem
                        label={'Biaya Registrasi'}
                        value={fees.registration.toLocaleString('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        })}
                      />
                      <PaymentDialogItem
                        label={'Iuran Tahun Pertama'}
                        value={fees.subscription.toLocaleString('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        })}
                      />
                      {fees.card !== 0 && (
                        <PaymentDialogItem
                          label={'Biaya Pencetakan Kartu Anggota'}
                          value={fees.card.toLocaleString('id-ID', {
                              style: 'currency',
                              currency: 'IDR',
                          })}
                        />
                      )}
                  </div>
                  <PaymentDialogItem
                    label={'Total'}
                    value={fees.total.toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                    })}
                  />
              </div>
          </DialogContent>
          <DialogActions style={{ marginTop: 36, marginBottom: 24, justifyContent: 'center' }}>
              <Button style={{ width: '314px', backgroundColor: 'red' }} onClick={closeDialog}>
                  Kembali
              </Button>
              <Button style={{ width: '314px' }} onClick={handlePayment}>
                  Bayar
              </Button>
          </DialogActions>
      </Dialog>
    )
}

function PaymentDialogItem({ label, value }) {
    return (
      <div className="d-flex justify-content-between align-items-center">
          <div className="font-weight-bold">{label}</div>
          <div>{value}</div>
      </div>
    )
}
import React, { useState, useEffect } from 'react'
import { useRegistrationForm } from './RegistrationFormContext'
import { InputGroup, Form, Row, Col, Container, FormControl } from 'react-bootstrap'
import { MdHelp, MdLocationCity } from 'react-icons/md'
import Select, { components } from 'react-select'
import { FaAngleDown, FaMinus, FaPlus } from 'react-icons/fa'
import Button from 'react-bootstrap/Button'
import { IconButton } from '@material-ui/core'
import Palette from '../../Palette'
import Language from '../../models/Language'
import Upload from '../../models/Upload'
import Swal from 'sweetalert2'
import { AiFillCloseCircle } from 'react-icons/ai'

// Komponen dropdown indicator custom
const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FaAngleDown color={'grey'} />
            </components.DropdownIndicator>
        )
    )
}

// Opsi-opsi form
const MEMBERSHIP_OPTIONS = [
    {
        value: 'PENUH',
        label: 'Saya seorang penerjemah/juru bahasa profesional yang berpengalaman dan ingin mendaftar sebagai Anggota Profesional Himpunan Penerjemah Indonesia',
    },
    {
        value: 'MUDA',
        label: 'Saya seorang pemula di bidang penerjemahan dan/atau penjurubahasaan, atau belum dapat memenuhi persyaratan untuk menjadi Anggota Profesional dan, oleh karena itu, ingin mendaftar sebagai Anggota Aspiran',
    },
    {
        value: 'MITRA',
        label: 'Anggota Mitra',
    },
]

const PROFESSION_OPTIONS = [
    { value: 'Translator', label: 'Penerjemah' },
    { value: 'Interpreter', label: 'Juru bahasa' },
    { value: 'Both', label: 'Keduanya' },
]

const AVAILABILITY_OPTIONS = [
    { value: 'full-time', label: 'Purnawaktu' },
    { value: 'part-time', label: 'Paruh waktu' },
]

const TRANSLATOR_REQ_OPTIONS = [
    {
        label: '1. Telah menerjemahkan buku dan diterbitkan oleh penerbit terdaftar IKAPI',
        value: '1',
    },
    {
        label: '2. Melampirkan contoh terjemahan (beserta teks sumber) dengan volume 150.000 karakter termasuk spasi',
        value: '2',
    },
    { label: '3. Merupakan Penerjemah Tersumpah (SK Mentri)', value: '3' },
    { label: '4. Merupakan Penerjemah Tersumpah (SK Gubernur DKI Jakarta)', value: '4' },
    {
        label: '5. Telah bekerja (minimal) satu tahun di sebuah perusahaan sebagai penerjemah atau jabatan terkait penerjemah',
        value: '5',
    },
    { label: '6. Surat rujukan/referensi dari 3(tiga) klien yang berbeda', value: '6' },
]

export default function MembershipForm() {
    const form = useRegistrationForm()
    const MAX_FILE_SIZE = 10 * 1024 * 1024

    // Handle upload file
    const handleFileUpload = async (e, type) => {
        const model = new Upload()

        const file = e.target.files[0]
        //console.log('ETF', file)

        if (file && file > MAX_FILE_SIZE) {
            Swal.fire(
                '',
                'Ukuran file terlalu besar. Ukuran maksimum yang diizinkan adalah 10 MB.',
                'error',
            )
            return false
        }
        const formData = new FormData()

        formData.append('upload', file)
        formData.append('member_id', '-1')

        const result = await model.uploadImage(formData)

        //console.log('cekcek', result)

        if (type === 'translator') {
            let obj = {
                id: form.translatorReqFile.length + 1,
                name: file.name,
                location: result?.location,
            }
            form.setTranslatorReqFile([...form.translatorReqFile, obj])
        } else if (type === 'interpreter') {
            let obj = {
                id: form.interpreterReqFile.length + 1,
                name: file.name,
                location: result?.location,
            }
            form.setInterpreterReqFile([...form.interpreterReqFile, obj])
        } else if (type === 'mitraKtpSuratKerja') {
            let obj = {
                id: form.mitraKtpSuratKerjaFile.length + 1,
                name: file.name,
                location: result?.location,
            }
            form.setMitraKtpSuratKerjaFile([...form.mitraKtpSuratKerjaFile, obj])
        } else if (type === 'mitraKartuAnggota') {
            let obj = {
                id: form.mitraKartuAnggotaFile.length + 1,
                name: file.name,
                location: result?.location,
            }
            form.setMitraKartuAnggotaFile([...form.mitraKartuAnggotaFile, obj])
        }
    }

    // Filter bahasa yang tidak valid
    const filterValidLanguages = (languages) => {
        return languages.filter(
            (lang) =>
                !lang.language.includes('-----') &&
                !lang.language.startsWith('LEGACY') &&
                lang.language.trim() !== '' &&
                !lang.language.includes('--------------------------------------'),
        )
    }

    // Tambah pasangan bahasa
    const handleAddLanguagePair = () => {
        if (form.languageFrom && form.languageTo) {
            // Cegah bahasa sumber dan target sama
            if (form.languageFrom.value === form.languageTo.value) {
                alert('Bahasa sumber dan target tidak boleh sama')
                return
            }

            // Cek apakah pasangan sudah ada
            const isPairExist = form.selectedLanguagePair.some(
                (pair) =>
                    pair.language_from.value === form.languageFrom.value &&
                    pair.language_to.value === form.languageTo.value,
            )

            if (isPairExist) {
                alert('Pasangan bahasa ini sudah ada')
                return
            }

            const newPair = {
                language_from: form.languageFrom,
                language_to: form.languageTo,
            }
            form.setSelectedLanguagePair([...form.selectedLanguagePair, newPair])
            form.setLanguageFrom(null)
            form.setLanguageTo(null)
        }
    }

    // Hapus pasangan bahasa
    const handleRemoveLanguagePair = (index) => {
        const updatedPairs = [...form.selectedLanguagePair]
        updatedPairs.splice(index, 1)
        form.setSelectedLanguagePair(updatedPairs)
    }

    // Fetch daftar bahasa saat komponen mount
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const languages = await Language.getAllLanguages()

                // Filter dan urutkan bahasa
                const validLanguages = filterValidLanguages(languages)
                validLanguages.sort((a, b) => a.language.localeCompare(b.language))

                form.setLanguageSelection(
                    validLanguages.map((lang) => ({
                        value: lang.id,
                        label: lang.language,
                    })),
                )
            } catch (error) {
                console.error('Gagal memuat bahasa:', error)
                // Fallback data jika API gagal
                form.setLanguageSelection([
                    { value: 30, label: 'English' },
                    { value: 17, label: 'Indonesian' },
                    { value: 38, label: 'Japanese' },
                    { value: 32, label: 'French' },
                    { value: 48, label: 'Spanish' },
                    { value: 41, label: 'Mandarin' },
                ])
            }
        }

        fetchLanguages()
    }, [])

    return (
        <>
            {/* 1. Jenis Keanggotaan */}
            <div className="register-form-label mb-3">
                Pernyataan pilihan jenis keanggotaan (pilih salah satu)
            </div>

            <InputGroup className="mb-4">
                <div style={{ display: 'grid', gap: '15px', width: '100%' }}>
                    {MEMBERSHIP_OPTIONS.map((option) => (
                        <div
                            key={option.value}
                            style={{ display: 'flex', alignItems: 'flex-start' }}
                        >
                            <input
                                type="radio"
                                name="memberType"
                                checked={form.membershipType === option.value}
                                onChange={() => {
                                    form.setInformationDeclaration(false)
                                    form.setProfileVisibilityAgreement('')
                                    form.setRegulationCompliance(false)
                                    form.setProfession('')
                                    form.setTermsAgreement(false)
                                    form.setAvailability('')
                                    form.setPrintCardAgreement(false)
                                    form.setProfession('')
                                    form.setMitraKtpSuratKerjaFile([])
                                    form.setMitraKartuAnggotaFile([])
                                    form.setInterpreterReqFile([])
                                    form.setTranslatorReqFile([])
                                    form.setTranslatorReqType('1')
                                    form.setMembershipType(option.value)
                                }}
                                style={{ marginRight: '10px', marginTop: '3px' }}
                            />
                            <label style={{ fontSize: '1rem', lineHeight: '1.4' }}>
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            </InputGroup>

            {/* 2. Form Khusus Anggota Mitra */}
            {form.membershipType === 'MITRA' && (
                <>
                    {/*<div className="register-form-label mb-3">
                        Alasan Anda Mengajukan Diri Menjadi Anggota Mitra HPI (minimal 500 kata)
                    </div>
                     <div
                        style={{
                            backgroundColor: '#E9F3F4',
                            borderRadius: '5px',
                            marginBottom: '20px',
                            padding: '15px',
                        }}
                    >
                        <Form.Control
                            as="textarea"
                            rows={8}
                            placeholder="Tuliskan alasan lengkap Anda mengajukan diri menjadi Anggota Mitra HPI (minimal 500 kata)..."
                            value={form.mitraReason || ''}
                            onChange={(e) => form.setMitraReason(e.target.value)}
                            style={{
                                width: '100%',
                                border: 'none',
                                borderRadius: '5px',
                                padding: '15px',
                                fontSize: '1rem',
                                lineHeight: '1.5',
                                minHeight: '250px',
                                backgroundColor: '#E9F3F4',
                            }}
                        />
                    </div> */}

                    {/* Upload 1: KTP dan Surat Kerja */}
                    {form.citizenship === 'INDONESIAN' ? (
                        <>
                            <div className="register-form-label mb-3">
                                Syarat Pendaftaran Sebagai Mitra (WNI) - Salinan digital KTP dan
                                Surat Keterangan Kerja di lembaga pendidikan
                            </div>
                            <div>
                                <label
                                    className="btn btn-sm btn-secondary"
                                    htmlFor="mitraKtpSuratKerja"
                                >
                                    Unggah
                                </label>
                                <input
                                    style={{ display: 'none' }}
                                    id="mitraKtpSuratKerja"
                                    type="file"
                                    onChange={(e) => handleFileUpload(e, 'mitraKtpSuratKerja')}
                                />
                            </div>

                            <Row>
                                <Col md={12}>
                                    {form.mitraKtpSuratKerjaFile &&
                                        form.mitraKtpSuratKerjaFile?.map((value, key) => {
                                            return (
                                                <>
                                                    <a
                                                        href={value?.location}
                                                        alt=""
                                                        target="_blank"
                                                    >
                                                        <Button variant="link">
                                                            {value?.name}
                                                        </Button>
                                                    </a>

                                                    <a
                                                        className="text-secondary mr-4"
                                                        onClick={(e) => {
                                                            let filtered =
                                                                form.mitraKtpSuratKerjaFile?.filter(
                                                                    (v, key) => {
                                                                        return v?.id !== value?.id
                                                                    },
                                                                )
                                                            form.setMitraKtpSuratKerjaFile(filtered)
                                                        }}
                                                        href="javascript:;"
                                                    >
                                                        <AiFillCloseCircle />
                                                    </a>
                                                </>
                                            )
                                        })}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            {/* Upload 2: Kartu Anggota */}
                            <div className="register-form-label mt-3 mb-3">
                                Syarat Pendaftaran Sebagai Mitra (WNA) - Salinan digital Kartu
                                Anggota atau Surat Rekomendasi dari Asosiasi Profesi Penerjemah di
                                negara asal anda dan salinan digital KTP/Passport yang mencantumkan
                                kewarganegaraan anda.
                            </div>
                            <div>
                                <label
                                    className="btn btn-sm btn-secondary"
                                    htmlFor="mitraKartuAnggota"
                                >
                                    Unggah
                                </label>
                                <input
                                    style={{ display: 'none' }}
                                    id="mitraKartuAnggota"
                                    type="file"
                                    onChange={(e) => handleFileUpload(e, 'mitraKartuAnggota')}
                                />
                            </div>

                            <Row>
                                <Col md={12}>
                                    {form.mitraKartuAnggotaFile &&
                                        form.mitraKartuAnggotaFile?.map((value, key) => {
                                            return (
                                                <>
                                                    <a
                                                        href={value?.location}
                                                        alt=""
                                                        target="_blank"
                                                    >
                                                        <Button variant="link">
                                                            {value?.name}
                                                        </Button>
                                                    </a>

                                                    <a
                                                        className="text-secondary mr-4"
                                                        onClick={(e) => {
                                                            let filtered =
                                                                form.mitraKartuAnggotaFile?.filter(
                                                                    (v, key) => {
                                                                        return v?.id !== value?.id
                                                                    },
                                                                )
                                                            form.setMitraKartuAnggotaFile(filtered)
                                                        }}
                                                        href="javascript:;"
                                                    >
                                                        <AiFillCloseCircle />
                                                    </a>
                                                </>
                                            )
                                        })}
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
            {/* 3. Syarat Khusus untuk Profesional (Dengan Upload File) */}
            {form.membershipType === 'PENUH' && (
                <>
                    <div className="register-form-label mb-3">Profesi</div>
                    <InputGroup className="mb-4">
                        <div style={{ display: 'grid', gap: '10px', width: '100%' }}>
                            {PROFESSION_OPTIONS.map((option) => (
                                <div
                                    key={option.value}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <input
                                        type="radio"
                                        name="profession"
                                        checked={form.profession === option.value}
                                        onChange={() => form.setProfession(option.value)}
                                        style={{ marginRight: '10px' }}
                                    />
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    </InputGroup>
                    {(form.profession === 'Translator' || form.profession === 'Both') && (
                        <>
                            <div className="register-form-label mb-3">
                                SYARAT PENDAFTARAN SEBAGAI ANGGOTA PROFESIONAL (PENERJEMAH) - Pilih
                                Satu
                            </div>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            borderWidth: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#e9f3f4',
                                        }}
                                    >
                                        <MdHelp size={20} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <div style={{ flex: 1 }}>
                                    <Select
                                        className="form-control-custom"
                                        value={
                                            TRANSLATOR_REQ_OPTIONS.find(
                                                (opt) => opt.value === form.translatorReqType,
                                            ) || TRANSLATOR_REQ_OPTIONS[0]
                                        }
                                        onChange={(option) =>
                                            form.setTranslatorReqType(option.value)
                                        }
                                        options={TRANSLATOR_REQ_OPTIONS}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                borderWidth: 0,
                                                backgroundColor: '#e9f3f4',
                                                fontFamily: 'Signika',
                                                fontWeight: '600',
                                                color: '#8e8e8e',
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator,
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>
                            </InputGroup>

                            <div className="register-form-label mb-3">
                                Pemenuhan Syarat -{' '}
                                {TRANSLATOR_REQ_OPTIONS.find(
                                    (opt) => opt.value === form.translatorReqType,
                                )?.label || TRANSLATOR_REQ_OPTIONS[0].label}
                            </div>

                            <div>
                                <label className="btn btn-sm btn-secondary" htmlFor="translatorReq">
                                    Unggah
                                </label>
                                <input
                                    style={{ display: 'none' }}
                                    id="translatorReq"
                                    type="file"
                                    onChange={(e) => handleFileUpload(e, 'translator')}
                                />
                            </div>

                            <Row>
                                <Col md={12}>
                                    {form.translatorReqFile &&
                                        form.translatorReqFile?.map((values, key) => {
                                            return (
                                                <>
                                                    <a
                                                        href={values.location}
                                                        alt=""
                                                        target="_blank"
                                                    >
                                                        <Button variant="link">
                                                            {values.name}
                                                        </Button>
                                                    </a>
                                                    <a
                                                        className="text-secondary mr-4"
                                                        onClick={(e) => {
                                                            let filtered =
                                                                form.translatorReqFile?.filter(
                                                                    (v, key) => {
                                                                        return v?.id !== values?.id
                                                                    },
                                                                )
                                                            form.setTranslatorReqFile(
                                                                filtered,
                                                            )
                                                        }}
                                                        href="javascript:;"
                                                    >
                                                        <AiFillCloseCircle />
                                                    </a>
                                                </>
                                            )
                                        })}
                                </Col>
                            </Row>
                        </>
                    )}

                    {(form.profession === 'Interpreter' || form.profession === 'Both') && (
                        <>
                            <div className="register-form-label mt-3 mb-3">
                                SYARAT PENDAFTARAN SEBAGAI ANGGOTA PROFESIONAL (JURU BAHASA) - Surat
                                rujukan/referensi dari 3 (tiga) klien yang berbeda (dengan
                                mencantumkan nama, alamat, dan nomor yang dapat dihubungi) yang
                                menunjukkan bahwa Saudara telah melakukan pekerjaan yang memuaskan
                                sebagai juru bahasa dengan total jam kerja minimal 50 (lima puluh)
                                jam.
                            </div>

                            <div>
                                <label
                                    className="btn btn-sm btn-secondary"
                                    htmlFor="interpreterReq"
                                >
                                    Unggah
                                </label>
                                <input
                                    style={{ display: 'none' }}
                                    id="interpreterReq"
                                    type="file"
                                    onChange={(e) => handleFileUpload(e, 'interpreter')}
                                />
                            </div>

                            <Row>
                                <Col md={12}>
                                    {form.interpreterReqFile &&
                                        form.interpreterReqFile?.map((value, key) => {
                                            return (
                                                <>
                                                    <a
                                                        href={value?.location}
                                                        alt=""
                                                        target="_blank"
                                                    >
                                                        <Button variant="link">
                                                            {value?.name}
                                                        </Button>
                                                    </a>

                                                    <a
                                                        className="text-secondary mr-4"
                                                        onClick={(e) => {
                                                            let filtered =
                                                                form.interpreterReqFile?.filter(
                                                                    (v, key) => {
                                                                        return v?.id !== value?.id
                                                                    },
                                                                )
                                                            form.setInterpreterReqFile(
                                                                filtered,
                                                            )
                                                        }}
                                                        href="javascript:;"
                                                    >
                                                        <AiFillCloseCircle />
                                                    </a>
                                                </>
                                            )
                                        })}
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}

            {/* 4. Bagian Agensi/Perusahaan */}
            <div className="register-form-label mt-3">
                Jika Saudara merupakan karyawan di sebuah Agensi/Perusahaan, sebutkan nama dan kota,
                provinsi, dan negara Agensi/Perusahaan tempat Saudara bekerja.
            </div>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text className="input-group-prepend">
                        <MdLocationCity size={20} />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    placeholder="Nama Perusahaan; Provinsi; Negara"
                    className="form-control-custom"
                    onChange={(event) => {
                        form.setAgencyNamePlace(event.target.value)
                    }}
                    value={form.agencyNamePlace || ''}
                />
            </InputGroup>

            {/* 5. Ketersediaan */}
            <div className="register-form-label mt-3">Ketersediaan</div>
            <InputGroup className="mb-4">
                <div style={{ display: 'grid', gap: '10px', width: '100%', padding: '8px' }}>
                    {AVAILABILITY_OPTIONS.map((option) => (
                        <div key={option.value} style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="radio"
                                name="availability"
                                checked={form.availability === option.value}
                                onChange={() => form.setAvailability(option.value)}
                                style={{ marginRight: '10px' }}
                            />
                            {option.label}
                        </div>
                    ))}
                </div>
            </InputGroup>

            {/* 6. Pasangan Bahasa */}
            <div className="register-form-label mt-3">Pasangan Bahasa</div>
            <InputGroup className="mb-4">
                <Col className="px-0">
                    <Container fluid>
                        {/* Daftar pasangan bahasa yang sudah dipilih */}
                        {form.selectedLanguagePair.length > 0 ? (
                            form.selectedLanguagePair.map((pair, idx) => (
                                <Row key={idx} style={{ marginBottom: 15 }}>
                                    <Col
                                        xs={5}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {pair.language_from.label}
                                    </Col>
                                    <Col
                                        xs={1}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {'>'}
                                    </Col>
                                    <Col
                                        xs={5}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {pair.language_to.label}
                                    </Col>
                                    <Col
                                        xs={1}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <IconButton
                                            size={'small'}
                                            onClick={() => handleRemoveLanguagePair(idx)}
                                            style={{ backgroundColor: Palette.PRIMARY }}
                                        >
                                            <FaMinus size={'12'} color={'white'} />
                                        </IconButton>
                                    </Col>
                                </Row>
                            ))
                        ) : (
                            <Row style={{ marginBottom: 15 }}>
                                <Col xs={12} style={{ textAlign: 'center', color: '#6c757d' }}>
                                    Belum ada pasangan bahasa yang dipilih
                                </Col>
                            </Row>
                        )}

                        {/* Form tambah pasangan bahasa baru */}
                        <Row>
                            <Col xs={5}>
                                <Select
                                    placeholder={'Pilih Bahasa Sumber'}
                                    value={form.languageFrom}
                                    onChange={(option) => form.setLanguageFrom(option)}
                                    options={form.languageSelection}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: '#e6e6e6',
                                        }),
                                    }}
                                    components={{ DropdownIndicator }}
                                    isClearable
                                />
                            </Col>
                            <Col
                                xs={1}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {'>'}
                            </Col>
                            <Col xs={5}>
                                <Select
                                    placeholder={'Pilih Bahasa Target'}
                                    value={form.languageTo}
                                    onChange={(option) => form.setLanguageTo(option)}
                                    options={form.languageSelection}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: '#e6e6e6',
                                        }),
                                    }}
                                    components={{ DropdownIndicator }}
                                    isClearable
                                />
                            </Col>
                            <Col
                                xs={1}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <IconButton
                                    size={'small'}
                                    onClick={handleAddLanguagePair}
                                    style={{ backgroundColor: Palette.PRIMARY }}
                                    disabled={!form.languageFrom || !form.languageTo}
                                >
                                    <FaPlus size={'12'} color={'white'} />
                                </IconButton>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </InputGroup>

            {/* 7. Deklarasi dan Pernyataan Kebersediaan */}
            {form.membershipType === 'PENUH' && (
                <>
                    <div className="register-form-label mt-4 mb-3">
                        Pernyataan Pemahaman dan Kebenaran Informasi
                    </div>
                    <div
                        style={{
                            padding: '15px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                            marginBottom: '20px',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <input
                                type="checkbox"
                                checked={form.informationDeclaration || false}
                                onChange={(e) => form.setInformationDeclaration(e.target.checked)}
                                style={{
                                    marginRight: '10px',
                                    marginTop: '3px',
                                    cursor: 'pointer',
                                    width: '16px',
                                    height: '16px',
                                }}
                            />
                            <label
                                style={{
                                    fontSize: '1rem',
                                    lineHeight: '1.4',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                }}
                            >
                                Saya memahami bahwa Himpunan Penerjemah Indonesia adalah organisasi
                                profesi yang keanggotaannya bersifat perorangan, dan keterangan yang
                                saya berikan di atas adalah keterangan tentang diri pribadi saya.
                                Keterangan yang saya berikan adalah benar pada saat mengisi formulir
                                ini.
                            </label>
                        </div>
                    </div>

                    <div className="register-form-label mt-3 mb-3">
                        Pernyataan Kebersediaan Untuk Menampilkan Profil di Direktori Penerjemah dan
                        Juru Bahasa Indonesia Jika Diterima Sebagai Anggota Penuh
                    </div>
                    <div
                        style={{
                            padding: '15px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                            marginBottom: '20px',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="radio"
                                name="profileVisibility"
                                checked={form.profileVisibilityAgreement === true}
                                onChange={() => form.setProfileVisibilityAgreement(true)}
                                style={{ marginRight: '10px' }}
                            />
                            Saya bersedia
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <input
                                type="radio"
                                name="profileVisibility"
                                checked={form.profileVisibilityAgreement === false}
                                onChange={() => form.setProfileVisibilityAgreement(false)}
                                style={{ marginRight: '10px' }}
                            />
                            Saya tidak bersedia
                        </div>
                    </div>
                </>
            )}

            {/* 8. Pernyataan Kepatuhan Terhadap Peraturan Organisasi */}
            {form.membershipType === 'PENUH' && (
                <>
                    <div className="register-form-label mt-4 mb-3">
                        Pernyataan Kepatuhan Terhadap Peraturan Organisasi
                    </div>
                    <div
                        style={{
                            padding: '15px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                            marginBottom: '20px',
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={form.regulationCompliance || false}
                            onChange={(e) => form.setRegulationCompliance(e.target.checked)}
                            style={{
                                marginRight: '10px',
                                marginTop: '3px',
                                cursor: 'pointer',
                                width: '16px',
                                height: '16px',
                            }}
                        />
                        <label
                            style={{
                                fontSize: '1rem',
                                lineHeight: '1.4',
                                cursor: 'pointer',
                                userSelect: 'none',
                            }}
                        >
                            Apabila saya diterima sebagai Anggota Aspiran/Profesional Himpunan Penerjemah
                            Indonesia (HPI), saya bersedia mematuhi semua aturan HPI, termasuk
                            AD/ART dan Kode Etik dan Kode Perilaku Profesi Penerjemah dan Juru
                            Bahasa, dan saya bersedia menerima sanksi apa pun yang dikenakan kepada
                            diri saya apabila saya terbukti melanggar aturan tersebut, termasuk
                            pembekuan dan/atau penghentian status keanggotaan saya di HPI, dan saya
                            tidak akan mengajukan gugatan, tuntutan, dan/atau mengambil tindakan
                            hukum apa pun terhadap Badan Pengurus HPI dan/atau anggota Badan
                            Pengurus HPI secara perorangan dan/atau secara organisasi.
                        </label>
                    </div>
                </>
            )}

            {/* 9. Pernyataan Keterikatan Dengan Syarat & Ketentuan HPI yang Berlaku */}
            {form.membershipType === 'PENUH' && (
                <>
                    <div className="register-form-label mt-4 mb-3">
                        Pernyataan Keterikatan Dengan Syarat & Ketentuan HPI yang Berlaku
                    </div>
                    <div
                        style={{
                            padding: '15px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                            marginBottom: '20px',
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={form.termsAgreement || false}
                            onChange={(e) => form.setTermsAgreement(e.target.checked)}
                            style={{
                                marginRight: '10px',
                                marginTop: '3px',
                                cursor: 'pointer',
                                width: '16px',
                                height: '16px',
                            }}
                        />
                        <label
                            style={{
                                fontSize: '1rem',
                                lineHeight: '1.4',
                                cursor: 'pointer',
                                userSelect: 'none',
                            }}
                        >
                            Dengan mengisi dan mengirim Formulir Pendaftaran ini kepada HPI, saya
                            menyatakan diri saya terikat pada Syarat dan Ketentuan yang berlaku di
                            HPI.
                        </label>
                    </div>
                </>
            )}

            {/* 10. Pernyataan Persetujuan Percetakan Kartu Keanggotaan */}
            {form.membershipType === 'PENUH' && (
                <>
                    <div className="register-form-label mt-4 mb-3">
                        Pernyataan Persetujuan Percetakan Kartu Keanggotaan
                    </div>
                    <div
                        style={{
                            padding: '15px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '5px',
                            marginBottom: '20px',
                        }}
                    >
                        <p style={{ marginBottom: '15px' }}>
                            Kami menyediakan opsi pencetakan kartu keanggotaan bagi Anda yang ingin
                            memiliki bukti fisik keanggotaan. Biaya pencetakan kartu yang akan
                            dikenakan adalah sebesar Rp100.000
                        </p>

                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <input
                                type="checkbox"
                                checked={form.printCardAgreement || false}
                                onChange={(e) => form.setPrintCardAgreement(e.target.checked)}
                                style={{
                                    marginRight: '10px',
                                    marginTop: '3px',
                                    cursor: 'pointer',
                                    width: '16px',
                                    height: '16px',
                                }}
                            />
                            <label
                                style={{
                                    fontSize: '1rem',
                                    lineHeight: '1.4',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                }}
                            >
                                Dengan mencentang opsi ini, saya setuju untuk mencetak kartu
                                keanggotaan saya
                            </label>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

import ApiRequest from '../util/ApiRequest'

export default class Membership {
    getAllHistory = async () => {
        return await ApiRequest.set('v1/membership/history', 'POST')
    }
    buy = async (body) => {
        return await ApiRequest.set('v1/membership/buy', 'POST', body)
    }

    getMembershipStatus = async () => {
        return await ApiRequest.set('v1/membership/active', 'GET')
    }

    changeMethod = async () => {
        return await ApiRequest.set('v1/membership/changeMethod', 'POST')
    }

    getPrices = async () => {
        return await ApiRequest.set('v1/membership_prices', 'GET')
    }
}

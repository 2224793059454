import React, { useEffect, useState } from 'react'
import { useRegistrationForm } from './RegistrationFormContext'
import { FormControl, InputGroup } from 'react-bootstrap'
import { MdCardMembership, MdEmail, MdHome, MdLocationOn, MdLock, MdPerson } from 'react-icons/md'
import { IoMdCalendar, IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Select, { components } from 'react-select'
import { FaAngleDown } from 'react-icons/fa'
import Provinces from '../../util/ProvinceData'
import Cities from '../../util/CityData'
import PhoneInput from 'react-phone-input-2'
import CustomDropzone from '../../components/Reusable/CustomDropzone'
import Upload from '../../models/Upload'
import moment from 'moment'

const CITIZENSHIP_OPTION = [
  { label: 'WNI', value: 'INDONESIAN' },
  { label: 'WNA', value: 'Foreign' },
]

export default function PersonalDataForm() {
  const [isFullNameFocused, setFullNameFocused] = useState(false)
  const [isEmailFocused, setEmailFocused] = useState(false)
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [isPasswordConfirmVisible, setPasswordConfirmVisible] = useState(false)
  const [isPasswordFocused, setPasswordFocused] = useState(false)
  const [isPasswordConfirmFocused, setPasswordConfirmFocused] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('')
  const form = useRegistrationForm()

  const onProvinceChange = (target) => {
    setCities([])
    setSelectedCity('')
    form.setCity('')

    setCities([
      ...Cities.filter((city) => city.province_id === target).map((value) => ({
        label: value.type ? value.type + ' ' + value.city_name : value.city_name,
        value: value.city_id,
        province_id: value.province_id,
      })),
    ])
  }

  const onImagePicked = async function (image) {
    try {
      const model = new Upload();
      const formData = new FormData();

      formData.append('upload', image, image.name)
      formData.append('member_id', "-1")

      const result = await model.uploadImage(formData)
      form.setProfilePic(result.location)
    } catch (e) {
      console.error(e)
    }


  }

  useEffect(() => {
    setProvinces(
      Provinces.map((value) => ({ label: value.province, value: value.province_id })),
    )
    form.setBirthDate(moment("2000-01-01"))
  }, [])

  let maxDate = moment().subtract(18, "year").toDate()

  return (
    <div className='d-flex flex-column' style={{gap: 16}}>
      <FormItemContainer
        label={'NAMA LENGKAP'}
        startIcon={<MdPerson size={20} />}
        isFocused={isFullNameFocused}
        setFocused={setFullNameFocused}
      >
        <FormControl
          placeholder="Nama Lengkap"
          className="form-control-custom"
          onChange={(event) => {
            form.setFullName(event.target.value)
          }}
          onFocus={() => setFullNameFocused(true)}
          onBlur={() => setFullNameFocused(false)}
          value={form.fullName}
        />
      </FormItemContainer>

      <FormItemContainer
        label={'SUREL'}
        startIcon={<MdEmail size={20} />}
        isFocused={isEmailFocused}
        setFocused={setEmailFocused}
      >
        <FormControl
          placeholder="Surel"
          className="form-control-custom"
          onChange={(event) => {
            form.setEmail(event.target.value)
          }}
          onFocus={() => setEmailFocused(true)}
          onBlur={() => setEmailFocused(false)}
          value={form.email}
        />
      </FormItemContainer>

      <FormItemContainer label={'NOMOR TELEPON SELULER'}>
        <PhoneInput
          placeholder="Phone Number"
          className="form-control-custom"
          countryCodeEditable={false}
          autoFormat={false}
          country={'id'}
          preferredCountries={['id']}
          preserveOrder={['preferredCountries']}
          disableDropdown={false}
          value={form.mobilePhone}
          onChange={(e) => form.setMobilePhone(e)}
          inputStyle={{
            borderWidth: '0',
            backgroundColor: '#e9f3f4',
            outline: 'none',
            boxShadow: 'none',
            fontFamily: 'Signika',
            fontWeight: '600',
          }}
          buttonStyle={{
            backgroundColor: 'lightGray',
          }}
        />
      </FormItemContainer>

      <FormItemContainer
        label={'KATA SANDI'}
        startIcon={<MdLock size={20} />}
        isFocused={isPasswordFocused}
        setFocused={setPasswordFocused}
        visibilityIcon={true}
        isVisible={isPasswordVisible}
        setVisible={setPasswordVisible}
      >
        <FormControl
          autoComplete={'new-password'}
          placeholder="Kata Sandi"
          type={isPasswordVisible ? 'text' : 'password'}
          className="form-control-custom"
          onChange={(event) => {
            form.setPassword(event.target.value)
          }}
          onFocus={() => setPasswordFocused(true)}
          onBlur={() => setPasswordFocused(false)}
          value={form.password}
        />
      </FormItemContainer>

      <FormItemContainer
        label={'KONFIRMASI KATA SANDI'}
        startIcon={<MdLock size={20} />}
        isFocused={isPasswordConfirmFocused}
        setFocused={setPasswordConfirmFocused}
        visibilityIcon={true}
        isVisible={isPasswordConfirmVisible}
        setVisible={setPasswordConfirmVisible}
      >
        <FormControl
          placeholder="Konfirmasi Kata Sandi"
          type={isPasswordConfirmVisible ? 'text' : 'password'}
          className="form-control-custom"
          onChange={(event) => {
            form.setPasswordConfirm(event.target.value)
          }}
          onFocus={() => setPasswordConfirmFocused(true)}
          onBlur={() => setPasswordConfirmFocused(false)}
          value={form.passwordConfirm}
        />
      </FormItemContainer>

      <div>
        <div className="register-form-label mt-3">JENIS KELAMIN</div>
        <InputGroup>
          <div style={{ display: 'grid' }}>
            <div>
              <input
                name="gender"
                type="radio"
                value="M"
                onClick={(e) => form.setGender(e.target.value)}
              />
              &nbsp;Laki-laki
            </div>
            <div>
              <input
                name="gender"
                type="radio"
                value="F"
                onClick={(e) => form.setGender(e.target.value)}
              />
              &nbsp;Perempuan
            </div>
          </div>
        </InputGroup>
      </div>

      <FormItemContainer label={'TEMPAT LAHIR'}>
        <FormControl
          placeholder="Tempat Lahir"
          className="form-control-custom"
          onChange={(event) => {
            form.setBirthPlace(event.target.value)
          }}
          value={form.birthPlace}
        />
      </FormItemContainer>

      <FormItemContainer label={'TANGGAL LAHIR'} startIcon={<IoMdCalendar size={20} />}>
        <div style={{ flex: 1 }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              // shouldDisableDate={(date)=>{
              //   console.log(date)
              //   let eighteenYearsAgo = moment().subtract(18,"year")
              //   return eighteenYearsAgo.isAfter(date)
              // }}
              maxDate={maxDate}
              // disableFuture
              openTo="year"
              format="DD/MM/YYYY"
              views={['year', 'month', 'date']}
              value={form.birthDate}
              onChange={form.setBirthDate}
              className="w-100"
              style={{
                background: '#e9f3f4',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </FormItemContainer>

      <FormItemContainer label={'KEWARGANEGARAAN'} startIcon={<MdLocationOn size={20} />}>
        <div style={{ flex: 1 }}>
          <CustomSelect
            placeholder={'Pilih Kewarganegaraan'}
            options={CITIZENSHIP_OPTION}
            onChange={(e) => {
              form.setCitizenship(e.value)
              if (e.label === 'WNA') {
                onProvinceChange('0')
                form.setProvince('Luar Negeri')
                form.setCity('Luar Negeri')
              } else {
                onProvinceChange('')
                form.setProvince('')
              }
            }}
            value={CITIZENSHIP_OPTION.find((c) => form.citizenship === c.value)}
          />
        </div>
      </FormItemContainer>

      <FormItemContainer
        label={'NOMOR KTP/PASPOR'}
        startIcon={<MdCardMembership size={20} />}
      >
        <FormControl
          placeholder="Nomor KTP/PASPOR"
          className="form-control-custom"
          onChange={(event) => {
            form.setIdNumber(event.target.value)
          }}
          value={form.idNumber}
        />
      </FormItemContainer>

      <FormItemContainer
        label={'TEMPAT PENERBITAN KTP/PASPOR'}
        startIcon={<MdCardMembership size={20} />}
      >
        <FormControl
          placeholder="Tempat KTP/PASPOR diterbitkan"
          className="form-control-custom"
          onChange={(event) => {
            form.setIdOrigin(event.target.value)
          }}
          value={form.idOrigin}
        />
      </FormItemContainer>

      <FormItemContainer label={'PROVINSI'} startIcon={<MdLocationOn size={20} />}>
        <div style={{ flex: 1 }}>
          <CustomSelect
            placeholder={'Pilih Provinsi'}
            options={
              form.citizenship == 'Foreign'
                ? [{ label: 'Luar Negeri', value: '0' }]
                : provinces
            }
            onChange={(e) => {
              form.setProvince(e.label)
              onProvinceChange(e.value)
            }}
            value={
              form.citizenship == 'Foreign'
                ? { label: 'Luar Negeri', value: '0' }
                : provinces.find((p) => form.province === p.value)
            }
          />
        </div>
      </FormItemContainer>

      <FormItemContainer label={'KOTA/KABUPATEN'} startIcon={<MdLocationOn size={20} />}>
        <div style={{ flex: 1 }}>
          <CustomSelect
            placeholder={'Pilih Kota/Kabupaten'}
            options={
              form.citizenship == 'Foreign'
                ? [{ label: 'Luar Negeri', value: '0', province_id: '0' }]
                : cities
            }
            value={
              form.citizenship == 'Foreign'
                ? { label: 'Luar Negeri', value: '0', province_id: '0' }
                : cities.find((c) => form.city === c.label) || null
            }
            onChange={(e) => form.setCity(e.label)}
            disabled={form.citizenship === 'Foreign'}
          />
        </div>
      </FormItemContainer>

      <FormItemContainer
        label={'ALAMAT LENGKAP SESUAI KTP/PASPOR'}
        startIcon={<MdHome size={20} />}
      >
        <FormControl
          placeholder="Sama seperti yang tertera di KTP/PASPOR"
          className="form-control-custom"
          onChange={(event) => {
            form.setAddress(event.target.value)
          }}
          value={form.address}
        />
      </FormItemContainer>

      <FormItemContainer
        label={'ALAMAT TEMPAT TINGGAL SAAT INI (jika berbeda dengan KTP/PASPOR)'}
        startIcon={<MdHome size={20} />}
      >
        <FormControl
          placeholder=""
          className="form-control-custom"
          onChange={(event) => {
            form.setCurrentAddress(event.target.value)
          }}
          value={form.currentAddress}
        />
      </FormItemContainer>

      <FormItemContainer label={'NOMOR TELEPON KEDUA (opsional)'}>
        <PhoneInput
          placeholder="Phone Number"
          className="form-control-custom"
          countryCodeEditable={false}
          autoFormat={false}
          country={'id'}
          preferredCountries={['id']}
          preserveOrder={['preferredCountries']}
          disableDropdown={false}
          value={form.homePhone}
          onChange={(e) => form.setHomePhone(e)}
          inputStyle={{
            borderWidth: '0',
            backgroundColor: '#e9f3f4',
            outline: 'none',
            boxShadow: 'none',
            fontFamily: 'Signika',
            fontWeight: '600',
          }}
          buttonStyle={{
            backgroundColor: 'lightGray',
          }}
        />
      </FormItemContainer>

      <FormItemContainer label={'FOTO DIRI'} noBG>
        <div>
          <CustomDropzone
            width={'150px'}
            height={'150px'}
            aspect={1}
            imageSrc={form.profilePic}
            prompt={
              <div style={{ fontFamily: 'Signika' }}>
                Tambahkan foto profil <br />
                (ukuran yang dianjurkan: 250 x 250)
              </div>
            }
            onDrop={(acceptedFiles) => onImagePicked(acceptedFiles)}
          />
        </div>
      </FormItemContainer>
    </div>
  )
}

function FormItemContainer({
                             label,
                             isFocused,
                             setFocused,
                             startIcon,
                             endIcon,
                             children,
                             visibilityIcon,
                             isVisible,
                             setVisible,
                             noBG
                           }) {
  return (
    <div>
      <div className="register-form-label mt-3">{label}</div>
      <InputGroup
        style={{
          backgroundColor: noBG ? null : '#e9f3f4',
          boxShadow: isFocused ? '0 0 10px #9ecaed' : 'none',
          border: isFocused ? '1px solid  #9ecaed' : 'none',
          borderRadius: 4,
        }}
      >
        {startIcon && (
          <InputGroup.Prepend>
            <InputGroup.Text className="input-group-prepend">
              {startIcon}
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}

        {children}

        {visibilityIcon && (
          <InputGroup.Prepend
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              paddingRight: 10,
              marginRight: 10,
              backgroundColor: '#e9f3f4',
            }}
            onClick={() => setVisible(!isVisible)}
          >
            {isVisible ? <IoMdEye size={20} /> : <IoMdEyeOff size={20} />}
          </InputGroup.Prepend>
        )}
      </InputGroup>
    </div>
  )
}

function CustomSelect(props) {
  return (
    <Select
      styles={{
        option: (provided, state) => ({
          ...provided,
          cursor: 'pointer',
        }),
        control: (provided) => ({
          ...provided,
          borderWidth: 0,
          backgroundColor: '#e9f3f4',
          fontFamily: 'Signika',
          fontWeight: '600',
          color: '#8e8e8e',
        }),
      }}
      components={{
        DropdownIndicator: (props) => {
          return (
            components.DropdownIndicator && (
              <components.DropdownIndicator {...props}>
                <FaAngleDown color={'grey'} />
              </components.DropdownIndicator>
            )
          )
        },
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  )
}

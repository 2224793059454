import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import Palette from '../../Palette'
import { useRegistrationForm } from './RegistrationFormContext'
import { Payment } from '@material-ui/icons'
import Alert from '../../components/Alert'
import moment from 'moment'

const REGISTRATION_FEES = {
    registration: 250000,
    subscription_if_penuh_or_mitra: 350000,
    subscription_if_muda: 150000,
    card: 100000,
}

export default function BiographyForm() {
    const [isDialogOpen, setDialogOpen] = useState(false)
    const form = useRegistrationForm()

    return (
        <>
            <div>
                <div className="d-flex flex-column" style={{ gap: 48 }}>
                    <FormItemContainer label={'Informasi Sertifikasi  Profesi (Non-TSN HPI)'}>
                        <Box sx={{ color: '#9E9E9E', marginTop: 16 }}>
                            Melalui Tes Sertifikasi Nasional (TSN), HPI menyelenggarakan program
                            sertifikasi profesi untuk Penerjemah, Juru Bahasa, dan Editor
                            Terjemahan. Selain TSN HPI, di Indonesia ada Lembaga Sertifikasi Profesi
                            lain yang juga memiliki program sertifikasi profesi untuk Penerjemah
                            Tersumpah, Penerjemah Teks Umum, Penerjemah Teks Sastra, Juru Bahasa
                            Konferensi, dan Juru Bahasa Kemasyarakatan, dengan sertifikat yang
                            dikeluarkan oleh Badan Nasional Sertifikasi Profesi (BNSP) Indonesia.
                        </Box>
                    </FormItemContainer>
                    <FormItemContainer
                        label={'Apakah Anda Penerjemah dan/atau Juru Bahasa Bersertifikat BNSP?'}
                    >
                        <InputGroup>
                            <div style={{ display: 'grid' }}>
                                <div>
                                    <input
                                        name="bnsp_certified"
                                        type="radio"
                                        onClick={(e) => form.setBnspCertified(true)}
                                        defaultChecked={form.isBnspCertified}
                                    />
                                    &nbsp;Ya
                                </div>
                                <div>
                                    <input
                                        name="bnsp_certified"
                                        type="radio"
                                        onClick={(e) => form.setBnspCertified(false)}
                                        defaultChecked={!form.isBnspCertified}
                                    />
                                    &nbsp;Tidak
                                </div>
                            </div>
                        </InputGroup>
                    </FormItemContainer>
                    {form.isBnspCertified && (
                        <FormItemContainer label={'Jika ya, mohon cantumkan nomor sertifikat anda'}>
                            <FormControl
                                placeholder="Nomor Sertifikat"
                                className="form-control-custom"
                                onChange={(event) => {
                                    form.setBnspNumber(event.target.value)
                                }}
                                value={form.bnspNumber}
                            />
                        </FormItemContainer>
                    )}
                    <FormItemContainer
                        label={
                            'Riwayat Hidup Singkat (Latar Belakang Pendidikan dan Pengalaman Sebagai Penerjemah/Juru Bahasa)'
                        }
                    >
                        <FormControl
                            as="textarea"
                            placeholder=""
                            className="form-control-custom"
                            onChange={(event) => {
                                form.setShortProfile(event.target.value)
                            }}
                            value={form.shortProfile}
                        />
                    </FormItemContainer>
                    <FormItemContainer label={'Alasan Saya Ingin Bergabung HPI'}>
                        <FormControl
                            as="textarea"
                            placeholder=""
                            className="form-control-custom"
                            onChange={(event) => {
                                form.setJoinReason(event.target.value)
                            }}
                            value={form.joinReason}
                        />
                        {form.membershipType === 'MITRA' && (
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ color: '#9E9E9E', fontSize: 14 }}
                            >
                                <div>Sekitar 500 kata untuk keanggotaan mitra</div>
                                <div>{form.joinReason.trim().split(/\s+/).length} / 500</div>
                            </div>
                        )}
                    </FormItemContainer>
                    <div className="d-flex flex-column">
                        <CustomCheckbox
                            name="agreementUnderstandingDeclaration"
                            onChange={(event) => {
                                form.setAgreementUnderstandingDeclaration(event.target.checked)
                            }}
                            label={
                                'Saya memahami bahwa Himpunan Penerjemah Indonesia (selanjutnya "HPI") adalah organisasi profesi yang keanggotaannya bersifat perorangan, dan keterangan yang saya berikan di atas adalah keterangan tentang diri pribadi saya.'
                            }
                            value={form.agreementUnderstandingDeclaration}
                        />
                        <CustomCheckbox
                            label={
                                'Saya menyatakan bahwa keterangan yang saya berikan di formulir ini adalah benar pada saat pengisiannya.'
                            }
                            value={form.dataValidDeclaration}
                            onChange={(event) => form.setDataValidDeclaration(event.target.checked)}
                            name={'dataValidDeclaration'}
                        />
                        <CustomCheckbox
                            name="complianceAgreement"
                            onChange={(event) => {
                                form.setComplianceAgreement(event.target.checked)
                            }}
                            label={
                                'Saya bersedia mematuhi semua peraturan dan tata tertib yang berlaku di HPI, termasuk semua Ketentuan Administrasi Keanggotaan, AD/ART HPI, dan Kode Etik & Kode Perilaku HPI.'
                            }
                            value={form.complianceAgreement}
                        />
                        <CustomCheckbox
                            name="complianceAgreement2"
                            onChange={(event) => {
                                form.setComplianceAgreement2(event.target.checked)
                            }}
                            label={
                                'Saya bersedia menerima sanksi apa pun yang dikenakan pada diri saya apabila terbukti bahwa saya telah melanggar aturan tersebut, yang bisa berarti penghentian keanggotaan saya di HPI, dan saya tidak akan mengajukan gugatan, tuntutan, dan proses hukum apa pun terhadap Badan Pengurus HPI dan/atau anggota Badan Pengurus HPI secara perorangan dan/atau HPI sebagai organisasi.'
                            }
                            value={form.complianceAgreement2}
                        />
                        {/*<CustomCheckbox*/}
                        {/*  name="complianceAgreement2"*/}
                        {/*  onChange={(event) => {*/}
                        {/*      form.setComplianceAgreement2(event.target.checked)*/}
                        {/*  }}*/}
                        {/*  label={*/}
                        {/*      `Saya ingin mencetak kartu anggota fisik dengan biaya Rp100.000 ${form.}`*/}
                        {/*  }*/}
                        {/*  value={form.complianceAgreement2}*/}
                        {/*/>*/}
                    </div>
                </div>
                {/*{form.isValidToContinue() && (*/}
                {/*    <div className="d-flex justify-content-center" style={{ marginTop: 64 }}>*/}
                {/*        <Button*/}
                {/*            style={{*/}
                {/*                fontFamily: 'Signika',*/}
                {/*                fontWeight: '600',*/}
                {/*                paddingTop: 10,*/}
                {/*                paddingBottom: 10,*/}
                {/*                backgroundColor: Palette.PRIMARY,*/}
                {/*                borderColor: Palette.PRIMARY,*/}
                {/*            }}*/}
                {/*            className="w-100"*/}
                {/*            disabled={!form.isValidToContinue()}*/}
                {/*            onClick={() => setDialogOpen(true)}*/}
                {/*        >*/}
                {/*            Daftar*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>

            <PaymentDialog
                open={isDialogOpen}
                handlePayment={() => {
                    setDialogOpen(false)
                    form.attemptRegister()
                }}
                closeDialog={() => setDialogOpen(false)}
            />
        </>
    )
}

function FormItemContainer({ label, children, startIcon }) {
    return (
        <div className="mt-3">
            {startIcon && (
                <InputGroup.Prepend>
                    <InputGroup.Text className="input-group-prepend">{startIcon}</InputGroup.Text>
                </InputGroup.Prepend>
            )}
            <div className="register-form-label-2">{label}</div>
            <div style={{ marginTop: 16 }}>{children}</div>
        </div>
    )
}

function CustomCheckbox({ name, onChange, value, label }) {
    return (
        <FormControlLabel
            control={<Checkbox name={name} color="primary" onChange={onChange} value={value} />}
            label={<>{label}</>}
        />
    )
}

function PaymentDialog({ open, handlePayment, closeDialog }) {
    const [fees, setFees] = useState({
        registration: REGISTRATION_FEES.registration,
        subscription: 0,
        card: 0,
        total: 0,
    })
    const form = useRegistrationForm()

    useEffect(() => {
        const feesData = {...fees}

        if (form.membershipType === 'PENUH' || form.membershipType === 'MITRA') {
            feesData.subscription = REGISTRATION_FEES.subscription_if_penuh_or_mitra
        } else {
            feesData.subscription = REGISTRATION_FEES.subscription_if_muda
        }

        if (form.printCardAgreement) {
            feesData.card = REGISTRATION_FEES.card
        }

        setFees({
            ...fees,
            ...feesData,
            total: fees.registration + feesData?.subscription + feesData?.card,
        })
    }, [form.membershipType, form.printCardAgreement])

    return (
        <Dialog open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>Pembayaran</DialogTitle>
            <DialogContent>
                <div>
                    <PaymentDialogItem
                        label={'Detail Pembayaran'}
                        value={moment().format('DD MMMM YYYY')}
                    />
                    <div style={{ marginTop: 24 }}>
                        <Alert
                            backgroundColor={'#E3E8F8'}
                            color={'#284A9A'}
                            type="info"
                            className="mx-0"
                            errorMsg={`Jika pengajuan anda ditolak, biaya registrasi akan dikembalikan penuh dipotong Rp50.000 untuk administrasi`}
                            textStyle={{ fontSize: 12, fontWeight: 500 }}
                        />
                    </div>
                    <div
                        className="d-flex flex-column"
                        style={{ marginTop: 24, gap: 16, marginBottom: 48 }}
                    >
                        <PaymentDialogItem
                            label={'Biaya Registrasi'}
                            value={fees.registration.toLocaleString('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                            })}
                        />
                        <PaymentDialogItem
                            label={'Iuran Tahun Pertama'}
                            value={fees.subscription.toLocaleString('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                            })}
                        />
                        {fees.card !== 0 && (
                            <PaymentDialogItem
                                label={'Biaya Pencetakan Kartu Anggota'}
                                value={fees.card.toLocaleString('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                })}
                            />
                        )}
                    </div>
                    <PaymentDialogItem
                        label={'Total'}
                        value={fees.total.toLocaleString('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        })}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: 36, marginBottom: 24, justifyContent: 'center' }}>

                <Button style={{ width: '314px', backgroundColor: 'red' }} onClick={closeDialog}>
                    Kembali
                </Button>

                <Button style={{ width: '314px' }} onClick={handlePayment}>
                    Bayar
                </Button>

            </DialogActions>
        </Dialog>
    )
}

function PaymentDialogItem({ label, value }) {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="font-weight-bold">{label}</div>
            <div>{value}</div>
        </div>
    )
}

import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { isMobile } from 'react-device-detect'
import { AiOutlineClose, GiHamburgerMenu } from 'react-icons/all'
import { Link, useLocation } from 'react-router-dom'

import Palette from '../Palette'
import logoHPI from '../assets/logoNew.png'
import Swal from 'sweetalert2'

const drawerWidth = 550

const nonActiveStyle = {}
const activeStyle = {
    fontWeight: 800,
    color: Palette.PRIMARY
}

function Header(props) {

    const location = useLocation()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showSideBar, setShowSidebar] = useState(false)

    let isLoggedIn = !!localStorage.getItem('user')

    useEffect(() => {
        // console.log(localStorage.getItem('user'))
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const sideBarFooter = (
        <>
            <ListItem>
                <div
                    style={{
                        fontSize: 15,
                        color: 'rgb(79, 79, 79)',
                        fontWeight: '300',
                    }}
                >
                    Others
                </div>
            </ListItem>

            <ListItem>
                <div style={{paddingLeft: 30, flex: 1}}>
                    <div style={{fontSize: 20, color: '#a52631'}}>
                        <Link
                            to={'/tentang-kami'}
                            style={{
                                fontFamily: 'Signika',
                                textDecorationLine: 'none',
                                color: '#1F2041',
                            }}
                            className="pl-3 ml-2 px-2 py-1 m-1"
                        >
                            About Us
                        </Link>
                    </div>
                </div>
            </ListItem>

            <ListItem>
                <div style={{paddingLeft: 30, flex: 1}}>
                    <div style={{fontSize: 20, color: '#a52631'}}>
                        <Link
                            to={'/faq'}
                            style={{
                                fontFamily: 'Signika',
                                textDecorationLine: 'none',
                                color: '#1F2041',
                            }}
                            className="pl-3 ml-2 px-2 py-1 m-1"
                        >
                            FAQ
                        </Link>
                    </div>
                </div>
            </ListItem>

            <ListItem>
                <div style={{paddingLeft: 30, flex: 1}}>
                    <div style={{fontSize: 20, color: '#a52631'}}>
                        <Link
                            to={'/syarat-dan-ketentuan'}
                            style={{
                                fontFamily: 'Signika',
                                textDecorationLine: 'none',
                                color: '#1F2041',
                            }}
                            className="pl-3 ml-2 px-2 py-1 m-1"
                        >
                            Terms & Conditions
                        </Link>
                    </div>
                </div>
            </ListItem>

            <ListItem>
                <div style={{paddingLeft: 30, flex: 1}}>
                    <div style={{fontSize: 20, color: '#a52631'}}>
                        <Link
                            to={'/hubungi-kami'}
                            style={{
                                fontFamily: 'Signika',
                                textDecorationLine: 'none',
                                color: '#1F2041',
                            }}
                            className="pl-3 ml-2 px-2 py-1 m-1"
                        >
                            Contact Us
                        </Link>
                    </div>
                </div>
            </ListItem>
        </>
    )

    const sideBarContent = (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        fontSize: 20,
                        flex: 1,
                        marginLeft: 15,
                        fontWeight: '700',
                        color: 'rgb(79, 79, 79)',
                    }}
                ></span>

                <IconButton
                    onClick={() => {
                        setShowSidebar(false)
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <AiOutlineClose color={Palette.PRIMARY}/>
                </IconButton>
            </div>

            <List
                style={{
                    overflow: 'auto',
                    marginBottom: '8em',
                }}
            >
                <ListItem>
                    <div style={{paddingLeft: 30, flex: 1}}>
                        <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                            <Link
                                style={{
                                    fontFamily: 'Signika',
                                    textDecorationLine: 'none',
                                    color: '#1F2041',
                                }}
                                to={'/'}
                                onClick={() => setShowSidebar(false)}
                                className="pl-3 ml-2 px-2 py-1 m-1"
                            >
                                Home
                            </Link>
                        </div>
                    </div>
                </ListItem>

                <div
                    style={{
                        height: 1,
                        backgroundColor: '#f0f0f0',
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                />
                <ListItem>
                    <div style={{paddingLeft: 30, flex: 1}}>
                        <div style={{fontSize: 20, color: '#a52631'}}>
                            <Link
                                to="/kegiatan"
                                style={{
                                    fontFamily: 'Signika',
                                    textDecorationLine: 'none',
                                    color: '#1F2041',
                                    textDecoration: 'none',
                                    ...(location.pathname.includes("/kegiatan") ? {...activeStyle} : {...nonActiveStyle})
                                }}
                                onClick={() => setShowSidebar(false)}
                                className="pl-3 ml-2 px-2 py-1 m-1"
                            >
                                Events
                            </Link>
                        </div>
                    </div>
                </ListItem>

                {!isLoggedIn && (
                    <>
                        <div
                            style={{
                                height: 1,
                                backgroundColor: '#f0f0f0',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        />
                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div
                                    style={{
                                        fontSize: 20,
                                        color: 'rgb(79, 79, 79)',
                                    }}
                                >
                                    <Link
                                        style={{
                                            fontFamily: 'Signika',
                                            textDecorationLine: 'none',
                                            color: '#1F2041',
                                        }}
                                        to={'/login'}
                                        onClick={() => setShowSidebar(false)}
                                        className="pl-3 ml-2 px-2 py-1 m-1"
                                    >
                                        Member Login
                                    </Link>
                                </div>
                            </div>
                        </ListItem>
                        <div
                            style={{
                                height: 1,
                                backgroundColor: '#f0f0f0',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        />
                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div
                                    style={{
                                        fontSize: 20,
                                        color: 'rgb(79, 79, 79)',
                                    }}
                                >
                                    <Link
                                        style={{
                                            fontFamily: 'Signika',
                                            textDecorationLine: 'none',
                                            color: '#1F2041',
                                        }}
                                        to={'/register'}
                                        onClick={() => setShowSidebar(false)}
                                        className="pl-3 ml-2 px-2 py-1 m-1"
                                    >
                                        Apply
                                    </Link>
                                </div>
                            </div>
                        </ListItem>
                    </>
                )}

                {!isLoggedIn && <></>}

                <div
                    style={{
                        height: 1,
                        backgroundColor: '#f0f0f0',
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                />
                {isLoggedIn && (
                    <>
                        <ListItem>
                            <div
                                style={{
                                    fontSize: 15,
                                    color: 'rgb(79, 79, 79)',
                                    fontWeight: '300',
                                }}
                            >
                                Laman Anggota
                            </div>
                        </ListItem>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: '#a52631'}}>
                                    <Link
                                        to="/member/akun"
                                        style={{
                                            fontFamily: 'Signika',
                                            textDecorationLine: 'none',
                                            color: '#1F2041',
                                            textDecoration: 'none',
                                            ...(location.pathname.includes("/akun") ? {...activeStyle} : {...nonActiveStyle})
                                        }}
                                        onClick={() => setShowSidebar(false)}
                                        className="pl-3 ml-2 px-2 py-1 m-1"
                                    >
                                        Account
                                    </Link>
                                </div>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: '#a52631'}}>
                                    <Link
                                        to="/member/membership"
                                        style={{
                                            fontFamily: 'Signika',
                                            textDecorationLine: 'none',
                                            color: '#1F2041',
                                            textDecoration: 'none',
                                            ...(location.pathname.includes("/membership") ? {...activeStyle} : {...nonActiveStyle})
                                        }}
                                        onClick={() => setShowSidebar(false)}
                                        className="pl-3 ml-2 px-2 py-1 m-1"
                                    >
                                        Membership
                                    </Link>
                                </div>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: '#a52631'}}>
                                    <Link
                                        to="/member/konten"
                                        style={{
                                            fontFamily: 'Signika',
                                            textDecorationLine: 'none',
                                            color: '#1F2041',
                                            textDecoration: 'none',
                                            ...(location.pathname.includes("/konten") ? {...activeStyle} : {...nonActiveStyle})
                                        }}
                                        onClick={() => setShowSidebar(false)}
                                        className="pl-3 ml-2 px-2 py-1 m-1"
                                    >
                                        Content
                                    </Link>
                                </div>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: '#a52631'}}>
                                    <Link
                                        onClick={() => {
                                            localStorage.removeItem('popUpReminder')
                                            localStorage.removeItem('user')
                                            setShowSidebar(false)
                                        }}
                                        to="/"
                                        style={{
                                            fontFamily: 'Signika',
                                            textDecorationLine: 'none',
                                            color: '#1F2041',
                                            textDecoration: 'none',
                                        }}
                                        className="pl-3 ml-2 px-2 py-1 m-1"
                                    >
                                        Keluar
                                    </Link>
                                </div>
                            </div>
                        </ListItem>
                    </>
                )}
                {sideBarFooter}
            </List>
        </>
    )

    return (
        <>
            <Row
                style={{
                    boxShadow: '0 2px 5px rgba(0,0,0,.05)',
                    paddingTop: windowWidth < 786 ? 5 : 15,
                    paddingBottom: windowWidth < 786 ? 10 : 15,
                }}
                className="mb-3"
            >
                <Container>
                    {/* <Modal show={showSideBar}>{sideBarDrawer}</Modal> */}
                    <Drawer
                        variant={isMobile ? 'temporary' : 'persistent'}
                        anchor={'left'}
                        open={showSideBar}
                    >
                        <div
                            style={{
                                width: windowWidth > drawerWidth ? drawerWidth : windowWidth,
                                display: 'flex',
                                flexDirection: 'column',
                                overflowY: 'hidden',
                                padding: 10,
                            }}
                            trackYProps={{
                                renderer: (props) => {
                                    const {elementRef, ...restProps} = props
                                    return (
                                        <span
                                            {...restProps}
                                            style={{...restProps.style, backgroundColor: 'white'}}
                                            ref={elementRef}
                                            className="trackY"
                                        />
                                    )
                                },
                            }}
                            smoothScrolling={true}
                        >
                            {sideBarContent}
                        </div>
                    </Drawer>
                    <div
                        className="position-absolute"
                        style={isMobile ? {display: 'none'} : {top: '25px'}}
                    >
                        <Link
                            style={{
                                fontFamily: 'Signika',
                                textDecorationLine: 'none',
                                color: '#1F2041',
                            }}
                            to={'/'}
                            className="p-2 m-1"
                        >
                            Home
                        </Link>

                        <Link
                            style={{
                                fontFamily: 'Signika',
                                textDecorationLine: 'none',
                                color: '#1F2041',
                                ...(location.pathname.includes("/kegiatan") ? {...activeStyle} : {...nonActiveStyle})
                            }}
                            to={'/kegiatan'}
                            className="p-2 m-1"
                        >
                            Events
                        </Link>

                        {isLoggedIn && (
                            <Link
                                style={{
                                    fontFamily: 'Signika',
                                    textDecorationLine: 'none',
                                    color: '#1F2041',
                                    ...(location.pathname.includes("/member") ? {...activeStyle} : {...nonActiveStyle})
                                }}
                                to={'/member/akun'}
                                className="p-2 m-1"
                            >
                                Laman Anggota
                            </Link>
                        )}

                        {!isLoggedIn && (
                            <Link
                                style={{
                                    fontFamily: 'Signika',
                                    textDecorationLine: 'none',
                                    color: '#1F2041',
                                    ...(location.pathname.includes("/login") ? {...activeStyle} : {...nonActiveStyle})
                                }}
                                to={'/login'}
                                className="p-2 m-1"
                            >
                                Member Login
                            </Link>
                        )}

                        {!isLoggedIn && (
                            <Link
                                style={{
                                    fontFamily: 'Signika',
                                    textDecorationLine: 'none',
                                    color: '#1F2041',
                                    ...(location.pathname.includes("/register") ? {...activeStyle} : {...nonActiveStyle})
                                }}
                                to={'/register'}
                                // to={{}}
                                // onClick={() => Swal.fire("PERHATIAN", "Menyusul ketentuan administrasi keanggotaan baru yang diatur dalam AD/ART HPI 2025-2027, proses pengajuan keanggotaan DITUTUP hingga 31 Maret 2025, dan dibuka kembali pada 01 April 2025.", 'warning')}
                                className="p-2 m-1"
                            >
                                Apply
                            </Link>
                        )}
                    </div>
                    {isMobile && (
                        <div onClick={() => setShowSidebar(true)} on>
                            <GiHamburgerMenu
                                className="position-absolute"
                                style={{top: '20px', width: '50px'}}
                            />
                        </div>
                    )}

                    <div className="d-flex justify-content-center">
                        <img src={logoHPI} style={{width: '120px'}} alt="HPI"/>
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '25px',
                            right: 0,
                            paddingRight: '20px',
                            marginRight: '40px',
                        }}
                    >
                        {isLoggedIn && !isMobile && (
                            <Link
                                onClick={() => {
                                    localStorage.removeItem('popUpReminder')
                                    localStorage.removeItem('user')
                                }}
                                style={{
                                    fontFamily: 'Signika',
                                    textDecorationLine: 'none',
                                    color: '#1F2041',
                                }}
                                to={'/'}
                                className="p-2 m-1"
                            >
                                Keluar
                            </Link>
                        )}
                    </div>
                </Container>
            </Row>
        </>
    )
}

export default Header

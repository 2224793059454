import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
    MdErrorOutline,
} from 'react-icons/md'
export default function Alert(props) {
    let { errorMsg, className, backgroundColor, color, textStyle } = props

    return (
        <>
            <Row
                style={{
                    backgroundColor: backgroundColor ? backgroundColor : '#ffc9cf',
                    color: props.color || '#e3192d',
                    alignItems: 'center',
                    border: `1px solid ${props.color || '#d5bab9'}`,
                    paddingRight: 10,
                    paddingTop: 7,
                    paddingBottom: 7,
                    marginBottom: 20,
                    borderRadius: 5,
                }}
                className={className}
            >
                <Col xs={1}>
                    <MdErrorOutline size={27} color={props.color || '#a25b5d'} />
                </Col>
                <Col
                    style={{
                        color: color ? color: '#a25b5d',
                        fontFamily: 'Signika',
                        fontWeight: '600',
                        marginLeft: 5,
                        ...textStyle
                    }}
                >
                    {errorMsg}
                </Col>
            </Row>
        </>
    )
}
